<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <form [formGroup]="patientForm">
      <h2>
        {{
          isCreation && (isAllOrg || isAllServices)
            ? ("forms.createPatientTitle" | translate) + ("forms.in" | translate)
            : !isCreation
            ? ("forms.updatePatientTitle" | translate)
            : ("forms.createPatientTitle" | translate)
        }}
        <span *ngIf="!isCreation">({{ selectedDepartment?.display }})</span>
        &nbsp;
      </h2>

      <!-- Organization Selector -->
      <mat-form-field *ngIf="isCreation && isAllOrg" floatLabel="never" class="department-select">
        <mat-select
          formControlName="organization"
          [disabled]="isAnonymous"
          [placeholder]="'forms.organisation' | translate"
          (selectionChange)="updateOrg($event)"
        >
          <mat-option *ngFor="let org of availableOrganizations" [value]="org.asReference">
            {{ org.asReference.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Department Selector (Services) -->
      <mat-form-field *ngIf="isCreation && isAllServices" floatLabel="never" class="department-select">
        <mat-select
          [compareWith]="compareReference"
          formControlName="healthcareService"
          [disabled]="isAnonymous"
          [placeholder]="'forms.services' | translate"
          (selectionChange)="updateDepartment($event)"
        >
          <mat-option *ngFor="let department of departments" [value]="department">
            {{ department.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ui-alert *ngIf="source === 'telemonitoring'" [options]="{ type: 'info' }">
        {{ "forms.userNotFound" | translate }}
      </ui-alert>

      <div class="spinner-container" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
      <ng-container *ngIf="!isLoading">
        <!-- alert INSI Status help -->
        <ui-alert
          [options]="{ type: 'help', fadeOut: true, closable: true }"
          *ngIf="showStatusHelp && country === FRANCE"
          (dismiss)="showStatusHelp = false"
        >
          <div class="insi-help-grid">
            <div [innerHTML]="'globalHelp.addPatientHelpINSI.provisionalId' | translate"></div>
            <div [innerHTML]="'globalHelp.addPatientHelpINSI.retrievedId' | translate"></div>
            <div [innerHTML]="'globalHelp.addPatientHelpINSI.validatedId' | translate"></div>
            <div [innerHTML]="'globalHelp.addPatientHelpINSI.qualifiedId' | translate"></div>
          </div>
        </ui-alert>
        <ui-alert *ngIf="isAnonymous" [options]="{ type: 'warning', showIcon: true, closable: true, fadeOut: true }">
          {{ "forms.anonymous" | translate }}
        </ui-alert>

        <!-- insi box -->
        <div *ngIf="country === FRANCE" class="insi-box">
          <p>{{ "forms.identityTitle" | translate }}</p>
          <p
            [ngClass]="{
              chip: true,
              'chip-provisoire': identityStatus === IDENTITY_STATUS.temporary,
              'chip-validee': identityStatus === IDENTITY_STATUS.validated,
              'chip-recuperee': identityStatus === IDENTITY_STATUS.recovered,
              'chip-qualifiee': identityStatus === IDENTITY_STATUS.qualified
            }"
          >
            {{ "forms.identityStatus." + identityStatus | translate }}
          </p>
          <button mat-icon-button type="button" (click)="showStatusHelp = !showStatusHelp">
            <mat-icon class="blue">help_outline</mat-icon>
          </button>
        </div>
        <div [class.insi-container]="country === FRANCE">
          <!-- SURNAME -->
          <mat-form-field>
            <input
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'name')"
              maxlength="100"
              cdkFocusInitial
              type="text"
              matInput
              placeholder="{{ country === FRANCE ? ('forms.FrName' | translate) : ('forms.name' | translate) }}"
              formControlName="name"
              required
              (blur)="transformToUpperCase($event.target.value, 'name')"
              [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="country === FRANCE && !patientForm.get('name').valid && nameError">
              {{ "forms.errors.ins." + nameError | translate }}
            </mat-error>
          </mat-form-field>

          <!-- FIRSTNAMES (LIST) (FR only) -->
          <ng-container *ngIf="country === FRANCE">
            <mat-form-field>
              <input
                maxlength="100"
                matInput
                placeholder="{{ 'forms.firstnames' | translate }}"
                formControlName="firstnames"
                (blur)="transformToUpperCase($event.target.value, 'firstnames')"
                [errorStateMatcher]="matcher"
              />
              <mat-hint align="start">{{ "forms.firstnamesHint" | translate }}</mat-hint>
              <mat-error *ngIf="!patientForm.get('firstnames').valid && firstnamesError">
                {{ "forms.errors.ins." + firstnamesError | translate }}
              </mat-error>
            </mat-form-field>
            <mat-icon class="custom-icon-small blue help-icon" [matTooltip]="'globalHelp.addPatientHelpINSI.firstnames' | translate">
              help_outline
            </mat-icon>
          </ng-container>
          <!-- FIRSTNAME -->
          <mat-form-field>
            <input
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'firstname')"
              maxlength="100"
              type="text"
              matInput
              placeholder="{{ country === FRANCE ? ('forms.FrFirstname' | translate) : ('forms.firstname' | translate) }}"
              formControlName="firstname"
              required
              (blur)="transformToUpperCase($event.target.value, 'firstname')"
              [errorStateMatcher]="matcher"
            />

            <mat-error *ngIf="country === FRANCE && patientForm.get('firstname').getError('pattern')">
              {{ "forms.errors.ins.pattern" | translate }}
            </mat-error>

            <mat-error *ngIf="country === FRANCE && patientForm.get('firstname').getError('isNotACorrectINSFirstnameError')">
              {{ "forms.isNotACorrectINSFirstnameError" | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                country === FRANCE &&
                !patientForm.get('firstname').valid &&
                !patientForm.get('firstname').getError('isNotACorrectINSFirstnameError') &&
                firstnameError
              "
            >
              {{ "forms.errors.ins." + firstnameError | translate }}
            </mat-error>
          </mat-form-field>
          <mat-icon
            *ngIf="country === FRANCE"
            class="custom-icon-small blue help-icon"
            [matTooltip]="'globalHelp.addPatientHelpINSI.firstname' | translate"
            >help_outline</mat-icon
          >

          <!-- PSEUDONAME (Not FR only at this place)-->
          <mat-form-field *ngIf="country !== FRANCE">
            <input
              type="text"
              matInput
              placeholder="{{ 'forms.pseudoname' | translate }}"
              formControlName="pseudoname"
              [errorStateMatcher]="matcher"
            />
          </mat-form-field>
          <br />

          <!-- ADDITIONAL NAMES-->
          <ng-container *ngIf="country === FRANCE">
            <!-- USE NAME -->
            <mat-checkbox style="margin-top: 1em" (change)="showMoreNames = !showMoreNames" [checked]="showMoreNames">
              {{ "forms.moreNames" | translate }}
            </mat-checkbox>
            <br />
            <ng-container *ngIf="showMoreNames">
              <mat-form-field>
                <input
                  (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'useName')"
                  type="text"
                  matInput
                  placeholder="{{ 'forms.useName' | translate }}"
                  formControlName="useName"
                  [errorStateMatcher]="matcher"
                />
              </mat-form-field>

              <!-- USE FIRSTNAME (nickname) -->
              <mat-form-field>
                <input
                  (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'useFirstname')"
                  type="text"
                  matInput
                  placeholder="{{ 'forms.useFirstname' | translate }}"
                  formControlName="useFirstname"
                  [errorStateMatcher]="matcher"
                />
              </mat-form-field>
              <br />
            </ng-container>
          </ng-container>

          <!-- BIRTHDATE -->
          <mat-form-field>
            <mat-label>{{ "forms.birthdate" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="birthDate"
              [max]="maxDate"
              formControlName="birthDate"
              placeholder="{{ 'forms.birthdateFormat' | translate }}"
              required
              (dateChange)="onBirthdateChange()"
              [errorStateMatcher]="matcher"
            />
            <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
            <mat-datepicker #birthDate></mat-datepicker>
          </mat-form-field>

          <!-- BIRTHPLACE (FR only) -->
          <app-town-server-side-search
            *ngIf="country === FRANCE && patientForm.get('birthDate').value"
            [date]="patientForm.get('birthDate').value"
            [formCtrl]="patientForm.get('birthplace')"
            [disabled]="!canUpdateRetrievedIdentityPermission && identityFromINSI"
            (valueChange)="downgradeIdentityIfNeeded($event)"
            [isRequired]="birthplaceRequired"
            style="max-width: 200px; min-width: 200px; margin-right: 5px"
          >
          </app-town-server-side-search>

          <!-- SEX -->
          <mat-form-field>
            <mat-label>{{ "forms.gender" | translate }}</mat-label>
            <mat-select formControlName="gender" required>
              <mat-option *ngFor="let gender of availableGender" [value]="gender.code">{{ gender.display }} </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- ADDITIONAL FIELDS -->
          <ng-container *ngIf="country === FRANCE">
            <p class="more" [ngClass]="showMore ? 'collapsed' : ''" (click)="showMore = !showMore">{{ "forms.additional" | translate }}</p>
            <div class="p-1" *ngIf="showMore">
              <div [ngClass]="{ 'mb-1': patientForm.get('ins').invalid || patientForm.get('oid').invalid }">
                <!-- INS -->
                <mat-form-field *ngIf="canUseINSiService">
                  <input
                    type="text"
                    matInput
                    placeholder="{{ 'forms.ins' | translate }}"
                    formControlName="ins"
                    maxlength="15"
                    minlength="15"
                    [errorStateMatcher]="matcher"
                  />

                  <mat-hint align="start" *ngIf="patientForm.get('ins').value && patientForm.get('oid').value === insType.NIA">
                    {{ "forms.insNiaWarning" | translate }}
                  </mat-hint>

                  <mat-error *ngIf="!patientForm.get('ins').valid || patientForm.hasError('invalidIns')">
                    {{ "forms.insError" | translate }}
                  </mat-error>
                </mat-form-field>

                <!-- OID -->
                <mat-form-field *ngIf="canUseINSiService">
                  <input
                    type="text"
                    matInput
                    placeholder="{{ 'forms.oid' | translate }}"
                    formControlName="oid"
                    pattern="[0-9.]*"
                    [errorStateMatcher]="matcher"
                  />
                  <mat-error *ngIf="!patientForm.get('oid').valid || patientForm.hasError('invalidOID')">
                    {{ "forms.oidError" | translate }}
                  </mat-error>
                </mat-form-field>

                <!-- Button to show INS history -->
                <mat-icon
                  class="ml-1 pointer color-button"
                  fontSet="fas"
                  fontIcon="fa-history"
                  *ngIf="insHistory?.length > 0 && !showInsHistory"
                  (click)="showInsHistory = true"
                  [matTooltip]="'forms.insHistoryBtn' | translate"
                ></mat-icon>
                <br />

                <!-- INS history box -->
                <ng-container *ngIf="showInsHistory">
                  <div class="ins-history">
                    <div class="icon-container">
                      <mat-icon class="pointer" (click)="showInsHistory = false">cancel</mat-icon>
                    </div>
                    <div class="content">
                      <h3>{{ "forms.insHistoryTitle" | translate }}</h3>
                      <table>
                        <tr *ngFor="let el of insHistory">
                          <td>{{ el.MatriculeIns }}</td>
                          <td>{{ el.Oid }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <br />
                </ng-container>
              </div>
              <!-- SOCIAL SECURITY -->
              <div [ngClass]="{ 'mb-1': patientForm.get('insuranceId').invalid }">
                <mat-form-field>
                  <input
                    type="text"
                    matInput
                    placeholder="{{ 'forms.socialSecurity' | translate }}"
                    formControlName="insuranceId"
                    maxlength="15"
                    minlength="15"
                    [errorStateMatcher]="matcher"
                  />

                  <mat-error *ngIf="!patientForm.get('insuranceId').valid || patientForm.hasError('invalidIns')">
                    {{ "forms.socialSecurityError" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- IDENTITY CHECK -->
              <mat-form-field>
                <mat-label>{{ "forms.identityVerification.title" | translate }}</mat-label>
                <mat-select formControlName="identityVerification">
                  <mat-option [value]="null" class="unselect"> - {{ "forms.null" | translate }} - </mat-option>
                  <mat-option *ngFor="let i of IDENTITY_VERIFICATION | EnumToArray" [value]="i">
                    {{ "forms.identityVerification." + i | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-icon class="custom-icon-small blue help-icon" [matTooltip]="'globalHelp.addPatientHelpINSI.identityCheck' | translate"
                >help_outline</mat-icon
              >

              <!-- ID CHECK METHOD -->
              <mat-form-field>
                <mat-label>{{ "forms.identityVerificationMethod.title" | translate }}</mat-label>
                <mat-select formControlName="identityVerificationMethod">
                  <mat-option [value]="null" class="unselect"> - {{ "forms.null" | translate }} - </mat-option>
                  <mat-option *ngFor="let i of IDENTITY_VERIFICATION_METHOD" [value]="i">
                    {{
                      i
                        | getIdentityVerificationMethodDisplay : serviceIdentityVerifMethod : sessionService.userLang : translateService
                        | translate
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-icon
                class="custom-icon-small blue help-icon"
                [matTooltip]="'globalHelp.addPatientHelpINSI.identityCheckMethod' | translate"
                >help_outline</mat-icon
              >
            </div>
          </ng-container>
          <div class="searchInsContainer" *ngIf="country === FRANCE">
            <mat-checkbox
              class="mt-1"
              *ngIf="canUseINSiService"
              [checked]="isSearching4InsiPatient"
              [disabled]="
                !patientForm.get('name').valid ||
                !patientForm.get('firstnames').valid ||
                !patientForm.get('gender').valid ||
                !patientForm.get('birthDate').valid ||
                patientForm.get('identityVerification').value === IDENTITY_VERIFICATION.suspicious ||
                patientForm.get('identityVerification').value === IDENTITY_VERIFICATION.fictive ||
                isSearching4InsiPatient ||
                !patientForm.get('firstname').value
              "
              (change)="searchForInsiPatient($event.checked)"
            >
              {{ "forms.insi" | translate }}
            </mat-checkbox>
            <mat-icon *ngIf="searchIsLoading">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
          </div>
          <ng-container *ngIf="country === FRANCE && foundInsPatient?.CodeCR">
            <ui-alert
              *ngIf="searchFinished"
              [options]="
                foundInsPatient.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && !notUsableInsPatient
                  ? { type: 'info' }
                  : foundInsPatient.CodeCR === INSIReturnCode.SEVERAL_INDENTITIES_FOUND
                  ? { type: 'warning' }
                  : { type: 'error' }
              "
              ><div class="flex">
                <div *ngIf="foundInsPatient.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && !notUsableInsPatient">
                  <p class="bold">{{ "insPatientSearchCases.oneIdentityFound" | translate }}</p>
                  <div class="mt-1">
                    <div>
                      <!-- Name at birth (Nom de naissance) -->
                      <span>{{ "forms.FrName" | translate }} : </span>
                      <b [ngClass]="{ highlighted: (foundInsPatient.NomNaissance | uppercase) !== patientForm.get('name').value }">{{
                        foundInsPatient.NomNaissance | titlecase
                      }}</b>
                    </div>
                    <!-- Firstname at birth (Prénom de naissance) -->
                    <!-- <div>
                      <span>{{ "forms.FrFirstname" | translate }} : </span>
                      <b>{{ foundInsPatient?.Prenom | titlecase }}</b>
                    </div> -->
                    <!-- Firstnames list (liste des prénoms) -->
                    <div>
                      <span>{{ "forms.firstnames" | translate }} : </span>
                      <b
                        [ngClass]="{
                          highlighted:
                            (foundInsPatient.ListePrenom | join : ' ') !==
                            (patientForm.get('firstnames').value | firstnamesToArray | join : ' ')
                        }"
                      >
                        {{ foundInsPatient.ListePrenom | join : " " | titlecase }}</b
                      >
                    </div>

                    <!-- Gender -->
                    <div>
                      <span>{{ "forms.gender" | translate }} : </span>
                      <b [ngClass]="{ highlighted: foundInsPatient.Sexe !== (patientForm.get('gender').value === 'male' ? 'M' : 'F') }">{{
                        "choiceLabel.gender." + foundInsPatient.Sexe | translate
                      }}</b>
                    </div>

                    <!-- Birthdate -->
                    <div>
                      <span>{{ "forms.birthdate" | translate }} : </span>
                      <b
                        [ngClass]="{
                          highlighted:
                            (foundInsPatient.DateNaissance | date : 'dd/MM/YYYY') !==
                            (patientForm.get('birthDate').value | date : 'dd/MM/YYYY')
                        }"
                      >
                        {{ foundInsPatient.DateNaissance | date : "dd/MM/YYYY" }}</b
                      >
                    </div>

                    <!-- BirthPlace -->
                    <div>
                      <span>{{ "forms.birthplaceCode" | translate }} : </span>
                      <b
                        [ngClass]="{
                          highlighted: foundInsPatient.LieuNaissance !== patientForm.get('birthplace').value?.reference
                        }"
                        >{{ foundInsPatient.LieuNaissance }}</b
                      >
                    </div>

                    <!-- Matricule INS -->
                    <div>
                      <span>{{ "forms.ins" | translate }} : </span>
                      <b>{{ foundInsPatient.MatriculeIns }}</b>
                    </div>
                    <!-- OID -->
                    <div>
                      <span>{{ "forms.oid" | translate }} : </span>
                      <b>{{ foundInsPatient.Oid }}</b>
                    </div>
                  </div>
                </div>
                <p *ngIf="foundInsPatient.CodeCR === INSIReturnCode.SEVERAL_INDENTITIES_FOUND">
                  {{ "insPatientSearchCases.severalIdentitiesFound" | translate }}
                </p>
                <p *ngIf="foundInsPatient.CodeCR === INSIReturnCode.NO_IDENTITY_FOUND">
                  {{ "insPatientSearchCases.noIdentityFound" | translate }}
                </p>
                <p *ngIf="foundInsPatient.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && notUsableInsPatient">
                  {{ "insPatientSearchCases.notUsablePatient" | translate }}
                </p>
                <p *ngIf="foundInsPatient.CodeCR === INSIReturnCode.ERROR">
                  {{ "insPatientSearchCases.error" | translate }}
                </p>

                <p *ngIf="foundInsPatient.CodeCR === INSIReturnCode.ACCESS_DENIED">
                  {{ "api.errors.ACCESS DENIED" | translate }}
                </p>

                <p *ngIf="foundInsPatient.CodeCR === INSIReturnCode.SERVER_ERROR">
                  {{ "api.errors.server-error" | translate }}
                </p>
                <div class="button-container">
                  <button
                    mat-stroked-button
                    type="button"
                    (click)="cancelInsSearch()"
                    *ngIf="
                      (foundInsPatient.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && !notUsableInsPatient) ||
                      foundInsPatient.CodeCR === INSIReturnCode.SEVERAL_INDENTITIES_FOUND ||
                      foundInsPatient.CodeCR === INSIReturnCode.ERROR ||
                      foundInsPatient.CodeCR === INSIReturnCode.ACCESS_DENIED ||
                      foundInsPatient.CodeCR === INSIReturnCode.SERVER_ERROR
                    "
                  >
                    {{ "btn.cancel" | translate }}
                  </button>
                  <button
                    mat-stroked-button
                    type="button"
                    (click)="cancelInsSearch()"
                    *ngIf="
                      foundInsPatient.CodeCR === INSIReturnCode.NO_IDENTITY_FOUND ||
                      (foundInsPatient.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && notUsableInsPatient)
                    "
                  >
                    {{ "btn.ok" | translate }}
                  </button>
                  <button
                    mat-flat-button
                    type="button"
                    (click)="useFoundInsPatient()"
                    style="margin-left: 5px"
                    *ngIf="foundInsPatient.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && !notUsableInsPatient"
                  >
                    {{ "btn.use" | translate }}
                  </button>
                  <button
                    mat-flat-button
                    type="button"
                    (click)="searchForInsiPatient(true)"
                    style="margin-left: 5px"
                    *ngIf="
                      foundInsPatient.CodeCR === INSIReturnCode.SEVERAL_INDENTITIES_FOUND ||
                      foundInsPatient.CodeCR === INSIReturnCode.ERROR ||
                      foundInsPatient.CodeCR === INSIReturnCode.ACCESS_DENIED ||
                      foundInsPatient.CodeCR === INSIReturnCode.SERVER_ERROR ||
                      foundInsPatient.CodeCR === INSIReturnCode.NO_IDENTITY_FOUND ||
                      (foundInsPatient.CodeCR === INSIReturnCode.ONE_INDENTITY_FOUND && notUsableInsPatient)
                    "
                  >
                    {{ "common.retry" | translate }}
                  </button>
                </div>
              </div>
            </ui-alert>
          </ng-container>
        </div>
        <div [class.p-1]="country === FRANCE">
          <!-- LANGUAGE -->
          <mat-form-field>
            <mat-label>{{ "forms.userLang" | translate }}</mat-label>
            <mat-select formControlName="userLang" required>
              <mat-option *ngFor="let lang of availableLangs" [value]="lang.code">{{ lang.display }}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- ETHNIC GROUP -->
          <mat-form-field>
            <mat-label>{{ "forms.ethnicGroup.title" | translate }}</mat-label>
            <mat-select formControlName="ethnicGroup">
              <mat-option *ngFor="let ethnicGroup of allEthnicGroups" [value]="ethnicGroup.snomedCode">
                {{ "forms.ethnicGroup." + ethnicGroup.i18nKey | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- PSEUDONAME FR only at this place-->
          <mat-form-field *ngIf="country === FRANCE">
            <input
              type="text"
              matInput
              placeholder="{{ 'forms.pseudoname' | translate }}"
              formControlName="pseudoname"
              [errorStateMatcher]="matcher"
            />
          </mat-form-field>
          <br />

          <!-- PHONE -->
          <mat-form-field>
            <input
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.letter, PREVENTCHARACTER.specialCharExceptPlus], 'phone')"
              type="phone"
              matInput
              placeholder="{{ 'forms.phone' | translate }}"
              formControlName="phone"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-error *ngIf="patientForm.get('phone').hasError('phoneValidator')">
              {{ "forms.checkPhoneFormat" | translate }}
            </mat-error>
          </mat-form-field>

          <!-- MAIL -->
          <mat-form-field>
            <input
              type="mail"
              matInput
              placeholder="{{ 'forms.mail' | translate }}"
              formControlName="mail"
              (click)="resetEmailAlreadyTaken()"
              required
              [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="!patientForm.get('mail').errors?.emailAlreadyTaken">{{ "page.login.email-required" | translate }}</mat-error>
            <mat-error *ngIf="!patientForm.get('mail').errors?.email && patientForm.get('mail').errors?.emailAlreadyTaken">
              {{ "forms.emailAlreadyTaken" | translate }}</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "forms.internalId" | translate }}</mat-label>
            <input
              type="text"
              matInput
              placeholder="{{ 'forms.internalId' | translate }}"
              formControlName="internalId"
              [errorStateMatcher]="matcher"
            />
          </mat-form-field>

          <ui-alert class="mt-1 mb-0" [options]="{ type: 'warning' }" *ngIf="patientForm.get('mail').errors?.emailAlreadyTaken">
            {{ "forms.samePerson" | translate }}
            <a [routerLink]="" (click)="sendMail()" class="link">{{ "forms.byClickingHere" | translate }}</a>
          </ui-alert>
          <br />

          <div formGroupName="practitioners">
            <mat-form-field>
              <mat-label>{{ "forms.nurse" | translate }}</mat-label>
              <mat-select formControlName="nurse" [errorStateMatcher]="atLeastOneMatcher">
                <mat-option [value]="null" class="unselect">- {{ "forms.null" | translate }} -</mat-option>
                <mat-option *ngFor="let nurse of availableNurses" [value]="nurse">{{ getPractitionerName(nurse) }} </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "forms.doctor" | translate }}</mat-label>
              <mat-select formControlName="doc" [errorStateMatcher]="atLeastOneMatcher">
                <mat-option [value]="null" class="unselect">- {{ "forms.null" | translate }} -</mat-option>
                <mat-option *ngFor="let doc of availableDoctors" [value]="doc">{{ getPractitionerName(doc) }} </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "forms.secretary" | translate }}</mat-label>
              <mat-select formControlName="secretary" [errorStateMatcher]="atLeastOneMatcher">
                <mat-option [value]="null" class="unselect">- {{ "forms.null" | translate }} -</mat-option>
                <mat-option *ngFor="let secretary of availableSecretaries" [value]="secretary">{{
                  getPractitionerName(secretary)
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "forms.roles.46255001" | translate }}</mat-label>
              <mat-select formControlName="pharmacist" [errorStateMatcher]="atLeastOneMatcher">
                <mat-option [value]="null" class="unselect">- {{ "forms.null" | translate }} -</mat-option>
                <mat-option *ngFor="let pharmacist of availablePharmacist" [value]="pharmacist">{{
                  getPractitionerName(pharmacist)
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="custom-error"
            *ngIf="
              patientForm.get('practitioners').hasError('atLeastOneRequired') && (submitted || patientForm.get('practitioners').touched)
            "
          >
            {{ "forms.checkAtLeastOne" | translate }}
          </div>

          <!-- INSURANCE -->
          <ng-container *ngIf="country !== FRANCE">
            <mat-form-field>
              <input
                #insInput
                matInput
                placeholder="{{ 'forms.insurance' | translate }}"
                formControlName="insurance"
                [matAutocomplete]="auto"
                [errorStateMatcher]="matcher"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="insuranceDisplay"
                panelWidth="auto"
                (closed)="insuranceAutoCompleteClose()"
              >
                <mat-option [value]="null" class="unselect"> - {{ "forms.null" | translate }} - </mat-option>
                <mat-option *ngFor="let insurance of filteredInsurances" [value]="insurance" class="custom-mat-option">
                  <div>
                    {{ (insurance.organizationName | split : "/")[0] }}
                    {{ (insurance.organizationName | split : "/")[1] ? " /" : "" }}
                  </div>
                  <div>{{ (insurance.organizationName | split : "/")[1] }}</div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "forms.insuranceId" | translate }}</mat-label>
              <input
                (keypress)="preventCharacter($event, [PREVENTCHARACTER.specialChar], 'insuranceId')"
                type="text"
                matInput
                placeholder="{{ 'forms.insuranceId' | translate }}"
                formControlName="insuranceId"
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "forms.nationalId" | translate }}</mat-label>
              <input
                (keypress)="preventCharacter($event, [PREVENTCHARACTER.specialChar], 'SSIN')"
                type="text"
                matInput
                placeholder="{{ 'forms.nationalId' | translate }}"
                formControlName="SSIN"
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
          </ng-container>

          <div
            *ngIf="
              monitoringOrgsRefs &&
              monitoringOrgsRefs.length > 0 &&
              !alreadyMonitoredByAnotherService &&
              (patientForm.get('monitoringOrgsLink') || patientForm.get('monitoringServicesLink'))
            "
          >
            <br />
            <mat-form-field class="font-12" *ngIf="patientForm.get('monitoringOrgsLink')">
              <mat-label>{{ "page.organizations.monitoringOrgsLink" | translate }}</mat-label>
              <mat-select formControlName="monitoringOrgsLink">
                <mat-option [value]="'none'" class="unselect">- {{ "forms.null" | translate }} -</mat-option>
                <mat-option *ngFor="let monitOrg of monitoringOrgsRefs" [value]="monitOrg">
                  {{ monitOrg.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="font-12" *ngIf="patientForm.get('monitoringServicesLink')">
              <mat-label>{{ "page.organizations.monitoringServicesLink" | translate }}</mat-label>
              <mat-select formControlName="monitoringServicesLink">
                <mat-option *ngFor="let monitService of monitoringServicesRefs" [value]="monitService">
                  {{ monitService.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <p *ngIf="monitoringOrgsRefs && monitoringOrgsRefs.length > 0 && alreadyMonitoredByAnotherService" style="max-width: 500px">
            <i>{{ "forms.alreadyMonitoredByAnotherService" | translate : { service: monitoringServiceName } }}</i>
          </p>

          <br />
          <mat-checkbox [disabled]="!isInternalIdValid()" *ngIf="isCreation" (change)="noSms = !noSms">{{
            "forms.noMail" | translate
          }}</mat-checkbox>
          <mat-checkbox class="ml-5" *ngIf="isCreation" (change)="userTest = !userTest">{{ "forms.userTest" | translate }} </mat-checkbox>
          <br />
          <mat-checkbox *ngIf="isCreation" [checked]="goToCp" (change)="onSwitchGoToCp()">{{
            "forms.assignCpDirectly" | translate
          }}</mat-checkbox>
          <br />
        </div>
      </ng-container>
    </form>
  </div>
  <div class="ui-dialog-actions">
    <button mat-raised-button *ngIf="isCreation" color="primary" [disabled]="saveInProgress" (click)="apply()">
      {{ "forms.createUser" | translate }}
    </button>
    <button mat-raised-button *ngIf="!isCreation" color="primary" [disabled]="saveInProgress" (click)="apply()">
      {{ "forms.updateUser" | translate }}
    </button>
  </div>
</div>
