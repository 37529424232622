<span #focus></span>
<mat-card class="widget pb-15" [ngClass]="{ 'big forcePaddingBottom': isBig }">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "observation.title" | translate }}</p>
    <a mat-icon-button aria-label="icon button with a ? icon" (click)="openObservationHelp()">
      <mat-icon>help_outline</mat-icon>
    </a>
    <span class="spacerBetween"></span>
    <app-widget-actions
      *ngIf="actions?.length"
      [actions]="actions"
      [isMobile]="isMobile"
      (actionTriggered)="handleAction($event.action)"
    ></app-widget-actions>
  </mat-toolbar>
  <mat-card-content>
    <!-- ======================== MODE SMALL ===============================-->
    <app-small-observation-table
      *ngIf="!isBig"
      [obsDefinition]="allDefinitions"
      [observations]="availableObservations"
      [loading]="loading"
    ></app-small-observation-table>
    <!-- ======================== MODE BIG =================================-->

    <!-- CHART CARD -->
    <mat-card *ngIf="isBig" class="widget-inside" [ngClass]="{ big: isBig }">
      <mat-toolbar color="primary" style="gap: 0.5rem">
        <p>{{ "table.graph" | translate }}</p>
        <span class="spacerBetween"></span>

        <div
          class="toggle-container"
          *ngIf="('dashboard/patientStreamObservationsLoincs' | isAuthorized | async) === true && streamObs?.length"
        >
          <p>{{ "observation.showStreamObs" | translate }}</p>
          <mat-slide-toggle [(ngModel)]="showStreamObs" (change)="onShowStreamChange()"></mat-slide-toggle>
        </div>

        <div
          class="datePickerContainer"
          *ngIf="
            ('dashboard/onlineDeviceExternalResources' | isAuthorized | async) === true &&
            showStreamObs &&
            streamObsExternalResourcesList?.length
          "
        >
          <mat-form-field>
            <mat-label>{{ "drugIntake.type.connectedDevice" | translate }}</mat-label>
            <mat-select [(value)]="selectedExternalResourceRef" (selectionChange)="setupPatientDevices()">
              <mat-option [value]="externalResource.reference" *ngFor="let externalResource of streamObsExternalResourcesList">
                {{ externalResource.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <form [formGroup]="filterFormGraph" *ngIf="!showStreamObs; else intervalPicker">
          <div class="datePickerContainer">
            <mat-form-field>
              <input
                cdkFocusInitial
                matInput
                [max]="maxFromDate"
                [matDatepicker]="fromDateGraph"
                formControlName="fromDate"
                placeholder="{{ 'itemFilter.fromDate' | translate }}"
                (dateChange)="dateChangeFrom($event.value)"
              />
              <mat-datepicker-toggle matSuffix [for]="fromDateGraph"></mat-datepicker-toggle>
              <mat-datepicker #fromDateGraph></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                [min]="minToDate"
                [matDatepicker]="toDateGraph"
                formControlName="toDate"
                placeholder="{{ 'itemFilter.toDate' | translate }}"
                (dateChange)="dateChangeTo($event.value)"
              />
              <mat-datepicker-toggle matSuffix [for]="toDateGraph"></mat-datepicker-toggle>
              <mat-datepicker #toDateGraph></mat-datepicker>
            </mat-form-field>
          </div>
        </form>
        <ng-template #intervalPicker>
          <div class="range-picker-container">
            <ui-date-range-max
              [(fromDate)]="this.fromDate"
              [(toDate)]="this.toDate"
              [maxValue]="6"
              [maxUnit]="'day'"
              (dateChange)="onDateChange()"
            ></ui-date-range-max>
          </div>
        </ng-template>
        <button
          mat-stroked-button
          matTooltip="{{ 'btn.exportObservations' | translate }}"
          [disabled]="
            (!currentDefinitions?.length && !showStreamObs) ||
            exportPdfInProgress ||
            (showStreamObs && !hasStreamData) ||
            (isNoneObservations && chartAndExportObs?.length <= 0)
          "
          (click)="onExportPDF()"
          aria-label="icon button with a pdf icon"
        >
          <mat-icon *ngIf="exportPdfInProgress"><mat-spinner diameter="24" class="white-spinner"></mat-spinner></mat-icon>
          <mat-icon *ngIf="!exportPdfInProgress">picture_as_pdf</mat-icon>
        </button>
        <button
          mat-stroked-button
          *ngIf="!isNoneObservations && showStreamObs && ('dashboard/exportObservations' | isAuthorized | async) === true"
          class="mr-5"
          (click)="onExport()"
          [disabled]="!currentDefinitions.length || exportCsvInProgress"
        >
          <mat-icon *ngIf="exportCsvInProgress"><mat-spinner diameter="24" class="white-spinner"></mat-spinner></mat-icon>
          <mat-icon *ngIf="!exportCsvInProgress" fontSet="fas" fontIcon="fa-file-csv"></mat-icon>
        </button>
        <app-item-selector [items]="sliderData" (changed)="changeFilter($event)" [useRadioButton]="showStreamObs"> </app-item-selector>
        <app-item-selector [items]="optData" logo="perm_data_setting" (changed)="changeGraphSettings($event)"> </app-item-selector>
      </mat-toolbar>
      <app-care-observation-chart
        *ngIf="!showStreamObs"
        [chartCustomOptData]="chartSettings"
        [observationsData]="chartAndExportObs"
        [superComponentsData]="currentDefinitions"
      >
      </app-care-observation-chart>
      <app-stream-obs-chart
        [patientId]="pu.user.caremateIdentifier"
        [fromDate]="fromDate"
        [toDate]="toDate"
        [externalRessourceRef]="selectedExternalResourceRef"
        (hasStreamData)="hasStreamData = $event"
        *ngIf="showStreamObs && selectedExternalResourceRef"
      ></app-stream-obs-chart>
      <mat-spinner *ngIf="showStreamObs && !selectedExternalResourceRef" diameter="24" class="white-spinner"></mat-spinner>
      <!-- noData is handle by the app-stream-obs-chart when showStreamObs is true -->
      <p class="text-center pt-05 pb-05" *ngIf="mergedObservations?.length === 0 && !showStreamObs">
        {{ "widget.noData" | translate }}
      </p>
    </mat-card>

    <!-- DATA CARD -->
    <mat-card class="widget-inside" *ngIf="isBig && !showStreamObs">
      <mat-toolbar color="primary">
        <p>{{ "table.table" | translate }}</p>
        <span class="spacerBetween"></span>
        <button
          mat-stroked-button
          *ngIf="!isNoneObservations && ('dashboard/exportObservations' | isAuthorized | async) === true"
          class="mr-5"
          (click)="onExport()"
          [disabled]="!currentDefinitions.length || exportCsvInProgress"
        >
          <mat-icon *ngIf="exportCsvInProgress"><mat-spinner diameter="24" class="white-spinner"></mat-spinner></mat-icon>
          <mat-icon *ngIf="!exportCsvInProgress" fontSet="fas" fontIcon="fa-file-csv"></mat-icon>
        </button>
        <button mat-stroked-button *ngIf="hasImages" class="mr-5" (click)="onExportImage()">
          {{ "observation.exportImage" | translate }}
        </button>
        <app-item-selector *ngIf="isBig" [items]="sliderData" [useRadioButton]="showStreamObs" (changed)="changeFilter($event)">
        </app-item-selector>
      </mat-toolbar>

      <div class="mini-bar">
        <form *ngIf="isBig" [formGroup]="filterFormTable">
          <div class="datePickerContainer">
            <mat-form-field>
              <input
                cdkFocusInitial
                matInput
                [max]="maxFromDate"
                [matDatepicker]="fromDateTable"
                formControlName="fromDate"
                placeholder="{{ 'itemFilter.fromDate' | translate }}"
                (dateChange)="dateChangeFrom($event.value)"
              />
              <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
              <mat-datepicker #fromDateTable></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                [min]="minToDate"
                [matDatepicker]="toDate"
                formControlName="toDate"
                placeholder="{{ 'itemFilter.toDate' | translate }}"
                (dateChange)="dateChangeTo($event.value)"
              />
              <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
          </div>
        </form>
        <!-- VIEW SELECTOR -->
        <div class="datePickerContainer mr-1">
          <mat-form-field>
            <mat-label>{{ "observation.view.title" | translate }}</mat-label>
            <mat-select [(value)]="activeView" (selectionChange)="updatePreference(false); computeMergedObservations()">
              <mat-option [value]="i" *ngFor="let i of OBSERVATION_VIEW | EnumToArray">
                {{ "observation.view." + i | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- group mat-checkbox together for better flex aligment  -->
        <div>
          <mat-checkbox *ngIf="activeView === OBSERVATION_VIEW.DEFAULT" [checked]="aggregateByDay" (change)="toggleAgregateByDay()">
            {{ "observation.aggregateByDay" | translate }}
          </mat-checkbox>

          <mat-checkbox [checked]="activateNorms" (change)="toggleActivateNorms()">
            {{ "observation.activateNorms" | translate }}
          </mat-checkbox>
        </div>
      </div>

      <!-- Default View -->
      <app-default-observation-table
        *ngIf="headers?.length >= 1 && activeView === OBSERVATION_VIEW.DEFAULT"
        [obsDefinition]="currentDefinitions"
        [observations]="filteredMergedObs"
        [initialObs]="chartAndExportObs"
        [aggregateByDay]="aggregateByDay"
        [headers]="headers"
      ></app-default-observation-table>

      <!-- View by moment -->
      <app-moment-observation-table
        #momentObsTableCmpt
        *ngIf="headers?.length >= 1 && activeView === OBSERVATION_VIEW.MOMENT"
        [obsDefinition]="currentDefinitions"
        [observations]="filteredMergedObs"
      ></app-moment-observation-table>

      <!-- Hourly view -->
      <app-hourly-observation-table
        *ngIf="headers?.length >= 1 && activeView === OBSERVATION_VIEW.HOURLY"
        [obsDefinitions]="currentDefinitions"
        [observations]="filteredMergedObs"
      ></app-hourly-observation-table>

      <p class="text-center mt-10 pb-10" *ngIf="headers?.length === 0">
        {{ "widget.noData" | translate }}
      </p>
    </mat-card>

    <!-- STAT CARD -->
    <app-patient-observations-stat-card
      *ngIf="isBig"
      [currentDefinitions]="currentDefinitions"
      [pu]="pu"
      [(showStreamObs)]="showStreamObs"
      (showStreamObsChange)="onShowStreamChange()"
      [sliderData]="sliderData"
      (sliderDataChange)="changeFilter($event)"
    ></app-patient-observations-stat-card>
  </mat-card-content>
</mat-card>
