<div id="QRPage" class="questionnaireListPage">
  <div class="toolbar">
    <div class="left">
      <mat-form-field>
        <mat-label><span class="fa fa-search mr-5"></span>{{ "itemFilter.search" | translate }}</mat-label>
        <input
          type="search"
          matInput
          [placeholder]="'itemFilter.searchPlaceHolder' | translate"
          [value]="globalSearchValue"
          (keyup)="updateSearch($event.target.value)"
          aria-label="Search by name or firstname"
        />
        <button *ngIf="globalSearchValue" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="updateSearch('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <form [formGroup]="filterFormTable">
      <div class="datePickerContainer ml-5">
        <mat-form-field class="datePickerField ml-5">
          <input
            cdkFocusInitial
            matInput
            [max]="maxFromDate"
            [matDatepicker]="fromDateTable"
            formControlName="fromDate"
            placeholder="{{ 'itemFilter.fromDate' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
          <mat-datepicker #fromDateTable></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="datePickerField ml-5">
          <input
            matInput
            [min]="minToDate"
            [max]="today"
            [matDatepicker]="toDate"
            formControlName="toDate"
            placeholder="{{ 'itemFilter.toDate' | translate }}"
          />
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <mat-slide-toggle
      class="mr-5 font-12"
      *ngIf="isAuthorizeShowAnonymousQr"
      (change)="handleToggleShowAnonymizedClicked()"
      labelPosition="before"
    >
      {{ "page.questionnaireList.showAnonymizedQuestionnaire" | translate }}
    </mat-slide-toggle>
    <button mat-icon-button (click)="openQuestionnaireListHelp()">
      <mat-icon style="font-size: 30px" matTooltip="{{ 'btn.help' | translate }}">help_outline</mat-icon>
    </button>
    <div class="spacer"></div>
    <!--<button mat-stroked-button (click)="activateCheckBox()"
            matTooltip="{{ 'page.patientlist.multiSelect' | translate }}">
            <mat-icon>check_box</mat-icon>
        </button>-->
    <button
      mat-stroked-button
      (click)="exportCSV()"
      matTooltip="{{ 'csv.export' | translate }}"
      *ngIf="('dashboard/questionnaireScoringsResults' | isAuthorized | async) === true"
      aria-label="Export questionnaires in CSV format"
      [disabled]="exportCsvInProgress"
    >
      <img *ngIf="!exportCsvInProgress" class="csv-icon" src="assets/images/custom-icon/csv-file.png" />
      <mat-icon *ngIf="exportCsvInProgress"><mat-spinner diameter="24" class="black-spinner"></mat-spinner></mat-icon>
    </button>
    <button mat-stroked-button (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}" aria-label="Clear all filters">
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
    <div class="right">
      <app-item-selector
        [items]="availableColumnItems"
        (changed)="availableColumnChanged($event)"
        [translate]="true"
        matTooltip="{{ 'page.patientlist.columnsSelect' | translate }}"
      >
      </app-item-selector>
    </div>
  </div>

  <div class="toolbar mat-elevation-z8" *ngIf="actionOnMultiple">
    {{ "itemSelection.selection" | translate : { length: selection.selected.length } }}
    <div class="actions">
      <button mat-stroked-button [disabled]="selection.selected.length <= 0">
        {{ "Export PDF" | translate }}
      </button>
    </div>
  </div>
  <mat-card [style.display]="displayedColumns.length > 0 ? 'block' : 'none'">
    <table
      mat-table
      matSort
      matSortDirection="desc"
      matSortActive="questionnaireDate"
      aria-label="Elements"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="columnDropped($event)"
      class="full-width-table"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            class="ml-15"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            class="ml-15"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.name" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.name' | translate }}"
            propertyName="name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('name')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>

      <!-- Firstname Column -->
      <ng-container matColumnDef="firstname">
        <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.firstname" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.firstname' | translate }}"
            propertyName="firstname"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('firstname')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.firstname }}</td>
      </ng-container>

      <!-- Birthday Column -->
      <ng-container matColumnDef="birthday">
        <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.birthdate" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.birthdate' | translate }}"
            propertyName="birthDate"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('birthDate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.birthDate | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Gender Column -->
      <ng-container matColumnDef="gender">
        <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.gender" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.gender' | translate }}"
            propertyName="gender"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('gender')"
            translatePrefix="choiceLabel.gender"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="fas fa-2x fa-mars" *ngIf="row.gender === 'male'"></span>
          <span class="fas fa-2x fa-venus" *ngIf="row.gender === 'female'"></span>
          <span class="fas fa-2x fa-genderless" *ngIf="row.gender !== 'male' && row.gender !== 'female'"></span>
        </td>
      </ng-container>

      <!-- QuestionnaireName Column -->
      <ng-container matColumnDef="questionnaireName">
        <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.questionnaireName" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.questionnaireName' | translate }}"
            propertyName="questionnaireName"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('questionnaireName')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.questionnaireName }}</td>
      </ng-container>

      <!-- QuestionnaireDate Column -->
      <ng-container matColumnDef="questionnaireDate">
        <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.questionnaireDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.questionnaireDate | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- QuestionnaireScore Column -->
      <ng-container matColumnDef="questionnaireScore">
        <th mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "model.patient.questionnaireScore" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <ul>
            <li *ngFor="let s of row.questionnaireScore?.slice(0, 3)">
              {{ s.scoreDisplay }}
              <i
                *ngIf="s.interpretation && s.interpretation[translateService.currentLang]"
                class="fa fa-info-circle"
                [matTooltip]="s.interpretation[translateService.currentLang]"
              ></i>
            </li>
          </ul>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.details' | translate }}"
            *ngIf="('dashboard/questionnaireScoringResult' | isAuthorized | async) === true"
            (click)="openDetails(row)"
            aria-label="button with a loupe icon"
          >
            <mat-icon class="custom-icon-small sub-align">search</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.exportQuestionnaire' | translate }}"
            *ngIf="
              ('dashboard/questionnaireScoringResult' | isAuthorized | async) === true &&
              ('exportToPDF' | isAuthorized : 'POST' | async) === true &&
              ('patientUser' | isAuthorized | async) === true
            "
            (click)="onExportPDF(row)"
            aria-label="button with a pdf icon"
            [disabled]="exportPdfInProgress"
          >
            <mat-icon *ngIf="!exportPdfInProgress" class="custom-icon-small sub-align">picture_as_pdf</mat-icon>
            <mat-icon *ngIf="exportPdfInProgress"><mat-spinner diameter="24" class="black-spinner"></mat-spinner></mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.details' | translate }}"
            *ngIf="!showAnonymized && ('/patient' | isAuthorized | async) === true"
            [routerLink]="['/patient', { id: encodeToB64(row.caremateIdentifier) }]"
            [state]="{ caremateIdentifier: row.caremateIdentifier }"
            matTooltip="{{ 'btn.goToPatient' | translate }}"
            aria-label="button with a patient face icon"
          >
            <mat-icon class="custom-icon-small sub-align">face</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="currentPageSize ? currentPageSize : 25" [pageSizeOptions]="[25, 50, 100]">
    </mat-paginator>
  </mat-card>

  <!-- Message to show when no column is selected -->
  <app-no-column-info *ngIf="displayedColumns.length === 0" style="position: relative; top: 100px"></app-no-column-info>
</div>
