import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { Contained, INPUT_TYPE, QuestionQuestionnaire, SPECIFIC_USE } from "src/app/models/questionnaire.interface";
import { NameSmiley } from "src/app/models/sharedInterfaces";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import uuid from "uuid-random";

@Component({
  selector: "app-choice-input",
  templateUrl: "./choice-input.component.html",
  styleUrls: ["./choice-input.component.scss"],
})
export class ChoiceInputComponent implements OnDestroy {
  @Input() lang: string;
  @Input() disabled = false;
  @Input() set specificUseSetter(specificUse: SPECIFIC_USE) {
    this.specificUse = specificUse;
    if (this.choiceQuestion && this.questionsOptions) {
      this.setup();
    }
  }
  @Output() containedChange = new EventEmitter<Contained[]>();
  @Input() set question(q: QuestionQuestionnaire) {
    this.choiceQuestion = q;
    this.setup();
  }
  @Output() questionChange = new EventEmitter<QuestionQuestionnaire>();

  @Input() set contained(value: Contained[]) {
    this.questionsOptions = value;
    if (this.questionsOptions && this.choiceQuestion && this.choiceQuestion.options && this.choiceQuestion.options.reference) {
      this.currentIndexContained = this.getCurrentIndexContained();
    } else {
      this.currentIndexContained = -1;
    }
    this.setup();
  }
  choiceQuestion: QuestionQuestionnaire;
  questionsOptions: Contained[];
  currentIndexContained = -1;
  // Type of input:
  isScale = false;
  isSmileyScale = false;
  isCheckboxes = false;
  isRadio = false;
  isDropDown = false;
  // Scales' variables:
  reverseSmiley = false;
  reverseValues = false;
  smileyOrder = ":) -> :(";
  valuesOrder = "0 -> 100";
  showChoices = true;
  // Numeric answer values variable:
  min = 0;
  max = 100;
  initial = 0;
  step = 1;
  public SPECIFIC_USE = SPECIFIC_USE; // for accessing Enum in template
  public specificUse: SPECIFIC_USE;
  public goodAnswers = [];
  public NAME_SMILEY = NameSmiley;
  public nameSmiley: NameSmiley = NameSmiley.smileyFace;

  private onDestroy$ = new Subject<void>();

  constructor(private translateService: TranslateService, private questService: QuestionnairesService) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Setup all the variables according to the inputs
   */
  private setup() {
    this.resetTypeOfInput();
    if (!this.choiceQuestion || !this.questionsOptions) {
      return;
    }
    this.setupTypeOfInput();
    if (this.isScale) {
      this.setupScalesVariables();
    }
    this.onScalesValuesChange(false);
    if (this.specificUse === SPECIFIC_USE.CONSENT || this.specificUse === SPECIFIC_USE.QUIZ) {
      // if quiz or consent
      if (!this.choiceQuestion.answerDisplay?.goodAnswers?.length) {
        this.choiceQuestion.answerDisplay.goodAnswers = this.goodAnswers;
        this.goodAnswers.push(this.questionsOptions[0].compose.include[0].concept[0].code);
      } else {
        this.goodAnswers = this.choiceQuestion.answerDisplay.goodAnswers;
      }
    }
  }

  /**
   * Put all the variables indicating the type of input back to default.
   */
  private resetTypeOfInput() {
    this.isScale = false;
    this.isSmileyScale = false;
    this.isCheckboxes = false;
    this.isRadio = false;
    this.isDropDown = false;
    this.showChoices = true;
  }

  /**
   * Setup the variable indicating the type of input
   */
  private setupTypeOfInput() {
    // Note: we are using boolean var instead of just one string var
    // describing the type because it is prettier on the html side.
    switch (this.choiceQuestion.answerDisplay.type) {
      case INPUT_TYPE.RANGE:
        this.isScale = true;
        break;
      case INPUT_TYPE.CHECKBOXES:
        this.isCheckboxes = true;
        break;
      case INPUT_TYPE.RADIO:
        this.isRadio = true;
        break;
      case INPUT_TYPE.DROPDOWN:
        this.isDropDown = true;
        break;
      default:
        this.isRadio = true;
        break;
    }
  }

  /**
   * Find the index of the answers options for the current question
   */
  private getCurrentIndexContained(): number {
    return this.questionsOptions.findIndex((model) => model.idSet === this.choiceQuestion.options.reference);
  }

  /**
   * Called when the values of min, max, step or initial changed.
   * Update the variable depending on it and check that the values are correct.
   */
  public onScalesValuesChange(needEmit = true): void {
    if (this.min === undefined || this.min === null) {
      this.min = 0;
    }
    if (this.max === undefined || this.max === null) {
      this.max = 1;
    }
    if (this.initial === undefined || this.initial === null) {
      this.initial = 0;
    }
    if (this.step === undefined || this.step === null) {
      this.step = 1;
    }
    if (this.min > this.max) {
      this.min = this.max;
    }
    if (this.step < 1) {
      this.step = 1;
    }
    if (this.step > this.max) {
      this.step = this.max;
    }
    if (this.initial < this.min) {
      this.initial = this.min;
    }
    if (this.initial > this.max) {
      this.initial = this.max;
    }
    if (this.reverseValues) {
      this.valuesOrder = this.max + " -> " + this.min;
    } else {
      this.valuesOrder = this.min + " -> " + this.max;
    }
    if (needEmit) {
      this.emitQuestionChanges();
    }
  }

  public emitQuestionChanges(): void {
    if (this.isScale) {
      this.choiceQuestion.answerDisplay.min = this.min?.toString();
      this.choiceQuestion.answerDisplay.max = this.max?.toString();
      this.choiceQuestion.answerDisplay.default = this.initial.toString();
      this.choiceQuestion.answerDisplay.step = this.step;
      this.choiceQuestion.answerDisplay.scaleReverseValue = this.reverseValues;
      this.choiceQuestion.answerDisplay.displayIsShown = this.showChoices;
      if (this.isSmileyScale) {
        this.choiceQuestion.answerDisplay.showMethod = this.reverseSmiley ? 1 : -1;
        this.choiceQuestion.answerDisplay.nameSmiley = this.nameSmiley;
      }
    }
    this.questionChange.emit(this.choiceQuestion);
  }

  // --------------------------------------------------------------------------
  // --------------------------  SCALES  --------------------------------------

  /**
   * Setup all the variables needed for the scales
   */
  private setupScalesVariables() {
    this.isScale = true;
    this.min = parseInt(this.choiceQuestion.answerDisplay.min, 10);
    this.max = parseInt(this.choiceQuestion.answerDisplay.max, 10);
    this.initial = parseInt(this.choiceQuestion.answerDisplay.default, 10);
    this.step = this.choiceQuestion.answerDisplay.step;
    this.reverseValues = this.choiceQuestion.answerDisplay.scaleReverseValue;
    this.isSmileyScale = this.choiceQuestion.answerDisplay.showMethod !== 0;
    this.reverseSmiley = this.choiceQuestion.answerDisplay.showMethod > 0;
    this.showChoices = this.choiceQuestion.answerDisplay.displayIsShown;
    this.nameSmiley = this.choiceQuestion.answerDisplay?.nameSmiley;
    this.handleValuesMeaningChange();
  }

  /**
   * Change the order of the smiley in the scale
   */
  public onReverseSmiley(): void {
    if (this.reverseSmiley) {
      this.smileyOrder = ":( -> :)";
    } else {
      this.smileyOrder = ":) -> :(";
    }
    this.emitQuestionChanges();
  }

  /**
   * Change the order of the values in the scale
   */
  public onReverseValues(): void {
    if (this.reverseValues) {
      this.valuesOrder = this.max + " -> " + this.min;
    } else {
      this.valuesOrder = this.min + " -> " + this.max;
    }
    this.emitQuestionChanges();
  }

  /**
   * Called when we chose to put meaningful sentences on the scales
   * (instead of just numbers) or when we change the number or order of sentences.
   * We force the min, step, etc... variable to match with the number of
   * sentences. And we make sure that the code of the answer is consistent.
   */
  public handleValuesMeaningChange(): void {
    if (!this.isScale) {
      return;
    }
    if (this.showChoices) {
      // Add valueSet if there isn't one
      if (this.currentIndexContained === -1) {
        const cid = uuid();
        const startCode = 0;
        const defaultChoiceText = this.translateService.instant("page.questionnaireEditor.choice");
        this.choiceQuestion.options = { reference: "", display: "" };
        this.questionsOptions.push(this.questService.generateValueSet(cid, startCode, defaultChoiceText));
        this.currentIndexContained = this.questionsOptions.length - 1;
        this.choiceQuestion.options.reference = cid;
      }
      const choices = this.questionsOptions[this.currentIndexContained].compose.include[0].concept;
      const iMax = choices.length - 1;
      this.step = 1;
      this.min = iMax > -1 ? Number(choices[0].code) : 0;
      this.initial = this.min;
      this.max = iMax > -1 ? Number(choices[iMax].code) : 0;
    } else {
      // Remove valueSet
      this.choiceQuestion.options = { reference: "", display: "" };
      if (this.currentIndexContained > -1) {
        const id = this.currentIndexContained;
        this.currentIndexContained = -1;
        this.questionsOptions.splice(id, 1);
      }
    }
    this.emitQuestionChanges();
  }

  public onValueSetChange(contained: Contained[]): void {
    this.questionsOptions = contained;
    this.handleValuesMeaningChange();
    this.containedChange.emit(contained);
  }

  public isValid(): boolean {
    if (this.specificUse === SPECIFIC_USE.CONSENT || this.specificUse === SPECIFIC_USE.QUIZ) {
      // if quiz or consent
      if (this.choiceQuestion.answerDisplay?.goodAnswers?.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public goodAnswersChange(): void {
    this.choiceQuestion.answerDisplay.goodAnswers = this.goodAnswers;
  }
}
