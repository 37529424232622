import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, concat, of } from "rxjs";
import { concatMap, first, last, map, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { HelpData } from "src/app/helpers/helpData";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { SOURCE } from "src/app/models/questionnaireResponse.model";
import { IQuestionnaireScoringSelection, IScoringSelection } from "src/app/models/questionnaireScoring.interface";
import { Scoring } from "src/app/models/questionnaireScoring.model";
import { Reference } from "src/app/models/reference.interface";
import { PreferenceService } from "src/app/providers/preference.service";
import { QRService } from "src/app/providers/qr-api.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { UserService } from "src/app/providers/user.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { Item } from "../item-selector/item-selector.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { QRDataSource } from "./QR-list-datasource";
import { AnswerNewQrComponent } from "./answer-new-qr/answer-new-qr.component";
import { ListOfPracQRComponent } from "./list-of-prac-qr/list-of-prac-qr.component";
import { PdfTypeChoiceComponent } from "./pdf-type-choice/pdf-type-choice.component";
import { QRDetailsComponent } from "./qr-details/qr-details.component";
import { QuestionnaireScoringGraphComponent } from "./qr-graph/questionnaireScoringGraph";

@Pipe({ name: "QRDate" })
export class QRDatePipe implements PipeTransform {
  transform(QR: Scoring): string {
    return QR.questionnaireResponse?.authored;
  }
}
@Pipe({ name: "QRName" })
export class QRNamePipe implements PipeTransform {
  transform(QR: Scoring): string {
    return QR.questionnaireResponse?.questionnaire?.display;
  }
}
@Pipe({ name: "canUpdate" })
export class CanUpdatePipe implements PipeTransform {
  transform(QR: Scoring, _identifier: string): boolean {
    return QR.questionnaireResponse.realSource === SOURCE.DASHBOARD; // QR.questionnaireResponse?.author?.reference === identifier;
  }
}
@Pipe({ name: "findPreviousQR" })
export class FindPreviousQRPipe implements PipeTransform {
  transform(currentQR: Scoring, QR: Scoring[]): Scoring | null {
    // keep only same QR and sort it more recent first
    const onlySameQr = QR.filter((q) => q.id === currentQR.id).sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1));
    // get it of current QR
    const indexCurrent = onlySameQr.findIndex((v) => v.selfId === currentQR.selfId);
    // return previous questionnaire, must be in the next index, if none exist, return null
    return onlySameQr[indexCurrent + 1] || null;
  }
}

@Component({
  selector: "app-patient-qr",
  templateUrl: "./patient-QR.component.html",
  styleUrls: ["./patient-QR.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientQRComponent extends WidgetBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  private isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isBig$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public actions: WidgetActionConfig[] = [
    {
      type: "button",
      icon: "add",
      label: "btn.newQuestionnaire",
      ariaLabel: "icon button with a plus icon",
      condition$: combineLatest([this.isAdmin$, this.userService.isAuthorized("questionnaireresponse", "POST")]).pipe(
        map(([isAdmin, isAuthorized]) => !isAdmin && isAuthorized)
      ),
      action: (): unknown => this.openListOfQr(),
    },
    {
      type: "button",
      icon: "open_in_full",
      label: "btn.details",
      ariaLabel: "icon button with an open icon",
      condition$: combineLatest([this.isBig$, this.isMobile$]).pipe(map(([isBig, isMobile]) => !isBig && !isMobile)),
      action: (): unknown => this.onPlus(),
    },
    {
      type: "button",
      icon: "close_fullscreen",
      label: "btn.reduce",
      ariaLabel: "icon button with a close fullscreen icon",
      condition$: this.isBig$, // Only show if in 'big' state
      action: (): unknown => this.onPlus(),
    },
  ];
  @Input() set patientUser(pu: PatientUser) {
    this.pu = pu;
    if (pu?.user?.caremateIdentifier) {
      this.patientId = pu.user.caremateIdentifier;
      this.patientRef = {
        reference: pu.user.caremateIdentifier,
        display: `${pu.user.name} ${pu.user.firstname}`,
      };
      if (this.dataSource) {
        this.sessionService.needRefresQRDataList();
        // patch because set patientUser is done after view rendering. Could be solved with a resolver on the patient page.
      }
    }
  }
  @ViewChild(MatTable) table: MatTable<Scoring>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild("graph") graph: QuestionnaireScoringGraphComponent;
  @ViewChild("focus") target: ElementRef;

  public scrollAfterDataInit = false;
  public displayedColumns: string[] = this.isMobile
    ? ["author", "name", "date", "action"]
    : ["author", "name", "date", "score", "difference", "same", "action"];
  public dataSource: QRDataSource;
  private currentPageSize: number;
  public defaultSort: MatSortable = {
    id: "date",
    start: "asc",
    disableClear: true,
  };
  public patientId: string;
  public editElementCode: string;
  public fromDate: moment.Moment;
  public toDate: moment.Moment;
  public sliderData: Item[] = [];
  public patientRef: Reference;
  public pu: PatientUser;
  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  private initiatedData = false;
  public QR: Scoring[] = [];
  public graphScorings;
  public isBig = false;
  public selectedQuestionnaireScorings: IQuestionnaireScoringSelection[] = [];

  public filterFormGraph = this.fb.group({
    fromDate: ["", [Validators.required]],
    toDate: ["", [Validators.required]],
  });

  public minToDate: moment.Moment = null;
  public maxFromDate: moment.Moment = null;
  private refreshInterval;

  /** Subject that emits when the component has been destroyed. */

  private isGettingData = false;
  public identifier: string;
  public isAdmin = false;
  public editAlreadyClicked = false;

  constructor(
    private preferenceService: PreferenceService,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private qrService: QRService,
    private questionnaireService: QuestionnairesService,
    private fb: UntypedFormBuilder,
    private sessionService: SessionService,
    public helpData: HelpData,
    public translateService: TranslateService,
    private tileManager: TileManager,
    protected responsiveService: ResponsiveService,
    private userService: UserService
  ) {
    super(responsiveService);
    this.isAdmin = this.sessionService.isAdmin();
    this.isAdmin$.next(this.isAdmin);
    this.isBig = TileManager.isBig(PatientWidgetName.QUESTIONNAIRES);
    this.isBig$.next(this.isBig);
    this.responsiveService.isHandset$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.computeColumns();
    });
  }

  ngOnInit(): void {
    this.dataSource = new QRDataSource();
    this.fromDate = moment().subtract(3, "month");
    this.toDate = moment();
    this.maxFromDate = this.toDate;
    this.minToDate = this.fromDate;
    this.identifier = this.sessionService.account.caremateIdentifier;
    this.updateDateForm();
  }

  ngAfterViewInit(): void {
    concat(of(1), this.sessionService.refreshQRDataList)
      .pipe(
        takeUntil(this.onDestroy$),
        concatMap(() => {
          if (this.patientId && this.pu) {
            return this.initDataAndAutoRefresh(this.preferenceService.getAutoRefreshWidget());
          }
          return of(null);
        })
      )
      .subscribe();

    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearAutoRefresh();
  }

  public computeColumns(): void {
    this.displayedColumns = this.isMobile
      ? ["author", "name", "date", "action"]
      : ["author", "name", "date", "score", "difference", "same", "action"];
  }

  public updateDateForm(): void {
    this.filterFormGraph.get("fromDate").setValue(this.fromDate);
    this.filterFormGraph.get("toDate").setValue(this.toDate);
  }

  private initDataAndAutoRefresh(_autoRefreshIntervalMs = 60000) {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.initData(); }, autoRefreshIntervalMs);
    return this.initData();
  }

  public clearAutoRefresh(): void {
    clearInterval(this.refreshInterval);
  }

  private initData() {
    if (this.isGettingData) {
      return;
    }
    this.isGettingData = true;
    this.initiatedData = true;
    return this.qrService.list(this.patientId, this.fromDate.toISOString(), this.toDate.toISOString()).pipe(
      first(),
      takeUntil(this.onDestroy$),
      concatMap((qr) => {
        this.QR = qr;
        // Here we need to empty existing data otherwise it will be mixed with other patients data
        if (this.selectedQuestionnaireScorings?.length) {
          this.selectedQuestionnaireScorings = [];
        }
        if (this.graphScorings) {
          this.graphScorings = undefined;
        }
        if (this.isBig) {
          this.initGraph();
        } else {
          this.init();
        }
        this.sliderData = this.selectedQuestionnaireScorings.map((v) => {
          return {
            checked: true,
            value: v.qId,
            display: v.qName,
          };
        });
        this.loadDataSource();
        this.isGettingData = false;
        return this.loadPreferences();
      })
    );
  }

  public async onEdit(QR: Scoring): Promise<void> {
    this.editAlreadyClicked = true;
    const questionnaire = await this.questionnaireService.getQuestionnaire(
      QR.questionnaireResponse.identifier.value?.split("/")?.[0],
      this.sessionService.userLang,
      false,
      true,
      QR.questionnaireResponse.questionnairePublicationDate
    );

    this.dialog.open(AnswerNewQrComponent, {
      data: {
        questionnaire,
        patientRef: this.patientRef,
        questionnaireResponse: QR.questionnaireResponse,
      },
      disableClose: true,

      height: this.isMobile ? "100vh" : "80vh",
      width: this.isMobile ? "100vw" : "70vw",
      minWidth: this.isMobile ? "100vw" : "",
      maxWidth: this.isMobile ? "100vw" : "",
    });
    this.editAlreadyClicked = false;
  }

  /**
   * return the right IScoring array
   */
  public getScorings(scorings: Scoring[]): Scoring[] {
    if (!scorings?.length) {
      return [];
    }
    scorings
      .filter((score) => {
        const reference = score.questionnaireResponse.questionnaire.reference;
        const removeLang = reference.split("/");
        const questionnaireRef = removeLang[0];
        const mStart = this.fromDate;
        const mEnd = this.toDate;
        if (mStart.isValid() && !mStart.isSameOrBefore(score.questionnaireResponse.modified, "day")) {
          return false;
        }
        if (mEnd.isValid() && !mEnd.isSameOrAfter(score.questionnaireResponse.modified, "day")) {
          return false;
        }
        return this.selectedQuestionnaireScorings.find((sqs) => questionnaireRef === sqs.qId && sqs.selected);
      })
      .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1));
    return scorings;
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator?.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference(false);
      }
      this.currentPageSize = this.paginator?.pageSize;
    });
  }

  private loadDataSource() {
    if (!this.QR) {
      this.loading = false;
      return;
    }
    this.dataSource.loadData(this.QR);
    this.sort.sort(this.defaultSort);
    this.loading = false;
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource?.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public clearFilter(): void {
    this.sort.sort(this.defaultSort);
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
  }

  /**
   * Preferences
   */
  public updatePreference(updateFocus: boolean): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_QR_LIST,
        parameters: {
          isBig: this.isBig,
          filters: this.dataSource.getAllFilters(),
        } as WidgetPatientParameter,
      })
      .subscribe(() => {
        if (this.isBig && updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  private loadPreferences() {
    return this.preferenceService.list(PreferenceContext.PATIENT_QR_LIST).pipe(
      last(),
      map((parameters: WidgetPatientParameter) => {
        if (parameters) {
          this.isBig = this.sessionService.globalPref?.keepLayoutFromPatientPage
            ? parameters.isBig
            : TileManager.isBig(PatientWidgetName.QUESTIONNAIRES);
          this.isBig$.next(this.isBig);
          this.tileManager.updateList(PatientWidgetName.QUESTIONNAIRES, this.isBig);
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
        this.sort.sort(this.defaultSort);
        if (this.scrollAfterDataInit && this.isBig && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
          this.scroll();
        }
      })
    );
  }

  /**
   * TODO : when we move mouse after click, scrollIntoView seems to not work
   */
  public scroll(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }

  public get isNoneData(): boolean {
    return this.dataSource?.data?.length === 0;
  }

  public openDetails(scoring: Scoring): void {
    this.dialog.open(QRDetailsComponent, {
      data: { QR: scoring.questionnaireResponse, scoring: scoring.scoring, pu: this.pu },
      height: this.isMobile ? "100vh" : "80vh",
      width: this.isMobile ? "100vw" : "70vw",
      minWidth: this.isMobile ? "100vw" : "",
      maxWidth: this.isMobile ? "100vw" : "",
    });
  }

  public onPlus(): void {
    this.isBig = !this.isBig;
    this.isBig$.next(this.isBig);
    this.tileManager.updateList(PatientWidgetName.QUESTIONNAIRES, this.isBig);
    this.updatePreference(true);
    this.loadDataSource();
    // this.initPaginatorAndSort();
    if (this.isBig) {
      this.initGraph();
    }
    this.scroll();
  }

  public updateLastFocus(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.QUESTIONNAIRES,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  private init() {
    if (!this.QR) {
      return;
    }
    // build questionnaire and scoring list
    for (const qs of this.QR) {
      // try to find same entry in the list
      const found = this.selectedQuestionnaireScorings.find((elem) => {
        return qs.questionnaireResponse.questionnaire.reference === elem.qId;
      });

      if (!found) {
        const selectedQS: IQuestionnaireScoringSelection = {
          qName: qs.questionnaireResponse?.questionnaire?.display,
          qDisplay: qs.questionnaireResponse?.group?.title || qs.questionnaireResponse?.questionnaire?.display,
          qId: qs.questionnaireResponse?.questionnaire.reference,
          selected: true,
          scoringIdentifier: [],
        };
        for (const s of qs.scoring) {
          const selScore: IScoringSelection = {
            name: s.identifier?.label ? s.identifier.label : this.translateService.instant("model.patient.questionnaireTotalScore"),
            id: s.identifier?.value,
            selected: true,
          };
          selectedQS.scoringIdentifier.push(selScore);
        }
        this.selectedQuestionnaireScorings.push(selectedQS);
      } else if (!found.scoringIdentifier && qs.scoring) {
        // fill with scoring if not already present
        for (const s of qs.scoring) {
          const selScore: IScoringSelection = {
            name: s.identifier?.label ? s.identifier.label : this.translateService.instant("model.patient.questionnaireTotalScore"),
            id: s.identifier.value,
            selected: true,
          };
          found.scoringIdentifier.push(selScore);
        }
      }
    }
  }

  private initGraph() {
    this.init();
    this.graphScorings = this.getScorings(this.QR);
  }

  public dateChangeFrom(value: moment.Moment): void {
    this.fromDate = value;
    this.refreshQR();
    this.filterFormGraph.get("fromDate").setValue(this.fromDate);
    this.filterFormGraph.get("toDate").setValue(this.toDate);
    this.initGraph();
  }

  public dateChangeTo(value: moment.Moment): void {
    this.toDate = value;
    this.refreshQR();
    this.filterFormGraph.get("fromDate").setValue(this.fromDate);
    this.filterFormGraph.get("toDate").setValue(this.toDate);
    this.initGraph();
  }

  public onChangeToDateGraph(): void {
    this.maxFromDate = this.filterFormGraph.get("toDate").value;
  }

  public onChangeFromDateGraph(): void {
    this.minToDate = this.filterFormGraph.get("fromDate").value;
  }

  public changeFilter(event: Item[]): void {
    this.selectedQuestionnaireScorings.forEach((v) => {
      const linkedItem = event.find((i) => i.value === v.qId);
      v.selected = linkedItem.checked;
      if (v.scoringIdentifier?.length) {
        v.scoringIdentifier.forEach((si) => (si.selected = linkedItem.checked));
      }
    });
    this.initGraph();
    this.graph.computeChartData();
  }

  public openQuestionnaireHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientQRHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  public openListOfQr(): void {
    this.dialog.open(ListOfPracQRComponent, {
      height: this.isMobile ? "100vh" : "",
      width: this.isMobile ? "100vw" : "",
      minWidth: this.isMobile ? "100vw" : "",
      maxWidth: this.isMobile ? "100vw" : "",
      data: { patientRef: this.patientRef },
      disableClose: true,
    });
  }

  public onExportPDF(scoring: Scoring): void {
    this.dialog.open(PdfTypeChoiceComponent, { data: { scoring: scoring, pu: this.pu }, disableClose: true });
  }

  private refreshQR() {
    this.initData().pipe(first()).subscribe();
  }
}
