<ng-container *ngIf="!isOtherOrg && !isPractitioner" class="patientAlreadyExistsContainer">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="usersFound?.length === 1">
    <h2>
      {{
        (usersFound[0].isRelated ? "dialog.patientAlreadyExists.isRelated.title" : "dialog.patientAlreadyExists.sameOrganization.title")
          | translate
      }}
    </h2>
    <h3>
      {{
        (usersFound[0].isRelated
          ? "dialog.patientAlreadyExists.isRelated.subtitle"
          : "dialog.patientAlreadyExists.sameOrganization.subtitle"
        ) | translate
      }}
    </h3>
  </div>

  <div *ngIf="usersFound?.length > 1">
    <h2>
      {{ "dialog.patientAlreadyExists.multipleUsers.title" | translate }}
    </h2>
    <h3>
      {{ "dialog.patientAlreadyExists.multipleUsers.subtitle" | translate }}
    </h3>
    <h3>
      {{
        (usersFound[indexSelected].isRelated
          ? "dialog.patientAlreadyExists.isRelated.subtitle"
          : "dialog.patientAlreadyExists.sameOrganization.subtitle"
        ) | translate
      }}
    </h3>
  </div>

  <div style="display: flex; flex-direction: row; justify-content: space-evenly">
    <mat-card
      *ngFor="let user of usersFound; let i = index"
      (click)="indexSelected = i"
      [style.border]="i === indexSelected ? '3px solid var(--comunicareBlue)' : ''"
    >
      <mat-list>
        <h3 mat-subheader>
          {{ (user.isRelated ? "dialog.patientAlreadyExists.existingRelated" : "dialog.patientAlreadyExists.existingPatient") | translate }}
        </h3>

        <mat-list-item *ngIf="!user.isRelated">
          <p>
            <b>{{ "forms.firstname" | translate }} :</b>
            {{ user.existingPatient.firstname }}
          </p>
        </mat-list-item>

        <mat-list-item>
          <p>
            <b>{{ "forms.name" | translate }} :</b>
            {{ user.existingPatient.name }}
          </p>
        </mat-list-item>

        <mat-list-item *ngIf="!user.isRelated">
          <p>
            <b>{{ "forms.birthdate" | translate }} :</b>
            {{ user.existingPatient.birthDate }}
          </p>
        </mat-list-item>

        <mat-list-item>
          <p>
            <b>{{ "forms.mail" | translate }} :</b>
            {{ user.existingPatient.mail }}
          </p>
          <mat-icon class="fail" *ngIf="user.sameEmail">error</mat-icon>
        </mat-list-item>

        <mat-list-item *ngIf="!user.isRelated">
          <p>
            <b>{{ "forms.phone" | translate }} :</b>
            {{ user.existingPatient.phone }}
          </p>
        </mat-list-item>

        <mat-list-item *ngIf="user.existingPatient.insurance">
          <p>
            <b>{{ "forms.insurance" | translate }} :</b>
            {{ user.existingPatient.insurance }}
          </p>
        </mat-list-item>
        <mat-list-item *ngIf="user.existingPatient.uniqueIds?.length">
          <div matListItemTitle>{{ "forms.uniqueIds" | translate }} :</div>
          <div matListItemLine *ngFor="let id of user.existingPatient.uniqueIds">
            {{ "forms." + (id.use ?? id.system) | translate }} : {{ id.value }}
          </div>
          <mat-icon class="fail" *ngIf="user.existingPatient.uniqueIds?.length">error</mat-icon>
        </mat-list-item>

        <mat-list-item *ngIf="user.existingPatient.healthcareService">
          <p>
            <b>{{ "forms.services" | translate }} :</b>

            <ng-container *ngFor="let healthcareService; let isLast = last; of: user.existingPatient.healthcareService">
              {{ healthcareService.display }}<span *ngIf="!isLast">,</span>
            </ng-container>
          </p>
        </mat-list-item>
      </mat-list>
    </mat-card>
  </div>

  <h3 class="mt-2" *ngIf="usersFound?.[indexSelected]">
    {{
      (usersFound[indexSelected].isRelated
        ? "dialog.patientAlreadyExists.isRelated.additionalText"
        : "dialog.patientAlreadyExists.sameOrganization.additionalText"
      ) | translate
    }}
  </h3>
  <br />
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true">
      {{ "dialog.patientAlreadyExists.sameOrganization.button.cancel" | translate }}</button
    >&nbsp;
    <button mat-raised-button color="primary" (click)="validate()">
      {{ "dialog.patientAlreadyExists.sameOrganization.button.validate" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="isPractitioner">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2>{{ "dialog.patientAlreadyExists.isPractitioner.title" | translate }}</h2>

  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true">
      {{ "btn.cancel" | translate }}</button
    >&nbsp;
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="isOtherOrg && !isPractitioner">
  <div class="buttons-bar">
    <button mat-icon-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2>
    {{
      userOtherOrg.mailAlreadyUsed
        ? ("dialog.patientAlreadyExists.differentOrganization.mailAlreadyUsed" | translate)
        : userOtherOrg.wrongMail
        ? ("dialog.patientAlreadyExists.differentOrganization.wrongMail" | translate)
        : userOtherOrg.verifyNamesAndSexe
        ? ("dialog.patientAlreadyExists.differentOrganization.verifyNamesAndSexe" | translate)
        : ("dialog.patientAlreadyExists.differentOrganization.title" | translate)
    }}
  </h2>

  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true">
      {{ "dialog.patientAlreadyExists.differentOrganization.button.cancel" | translate }}</button
    >&nbsp;
    <button mat-raised-button color="primary" (click)="sendMail()">
      {{ "dialog.patientAlreadyExists.differentOrganization.button.contact" | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
