import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import * as d3 from "d3";
import moment from "moment";
import { from } from "rxjs";
import { delay, first } from "rxjs/operators";
import { xAxisType, yAxisType } from "src/app/helpers/chart-helper";
import { IChartConfig, IStreamGraphLine } from "src/app/models/stream-observation-interface";
import { ObservationsService } from "src/app/providers/observations.service";
import { BaseChartComponent } from "../base-chart/base-chart.component";

@Component({
  selector: "app-stream-obs-chart",
  templateUrl: "./stream-obs-chart.component.html",
  styleUrls: ["./stream-obs-chart.component.scss", "../base-chart/base-chart.component.scss"],
})
export class StreamObsChartComponent extends BaseChartComponent implements OnChanges {
  @Input() private fromDate: moment.Moment;
  @Input() private toDate: moment.Moment;
  @Input() private patientId: string;
  @Input() loinc: string;
  @Input() externalRessourceRef: string;
  @Output() hasStreamData = new EventEmitter<boolean>();

  @ViewChild("legend") legend: ElementRef<HTMLDivElement>;
  public data: IStreamGraphLine[];

  private colorPalette = ["#FF5733", "#33C1FF", "#FF33A8", "#6ABF4B", "#FFB533", "#8D33FF", "#FF3358"];

  public chartConfig: IChartConfig = {
    margin: 30,
    width: 800,
    height: 400,
    xAxis: { type: xAxisType.XXIVHours },
    yAxis: { type: yAxisType.value, min: 0, max: 400 },
  };

  public legends: { color: string; date: moment.Moment; currentValue?: number }[] = [];
  public component: ElementRef; // needed to generate pdf in parent component
  public dataPoints: { value: number; minutesAfterMidnight: number }[];
  public selectedMinute: number;
  public sliderHandle: d3.Selection<SVGCircleElement, unknown, HTMLElement, unknown>;
  sliderTrack: d3.Selection<SVGLineElement, unknown, HTMLElement, unknown>;

  constructor(private observationsService: ObservationsService, private elementRef: ElementRef) {
    super();
    this.component = elementRef;
    this.onDrag = this.onDrag.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  async ngOnChanges(): Promise<void> {
    if (!this.externalRessourceRef && this.patientId) return;
    this.data = await this.observationsService
      .listOnlineDeviceData(
        this.patientId,
        this.fromDate?.format("YYYY-MM-DD"),
        this.toDate?.format("YYYY-MM-DD"),
        this.externalRessourceRef,
        this.loinc
      )
      .toPromise();

    this.hasStreamData.emit(this.data.length > 0 ? true : false);

    const dataReady = from(this.createChart()).pipe(first(), delay(500));
    dataReady.subscribe(() => {
      this.loading = false;
    });
  }

  public drawPaths(): void {
    this.legends = [];
    // Remove all old circles before drawing new ones
    this.svg?.selectAll("circle")?.remove();

    for (let index = 0; index < this.data.length; index++) {
      const d = this.data[index];
      this.dataPoints = d.values.map((el) => ({
        value: el.valueQuantity,
        minutesAfterMidnight: el.minutesAfterMidnight,
      }));
      this.legends.push({ color: this.colorPalette[index], date: moment(this.data[index].dateOfDay) });
      this.drawCircles(this.svg, index, this.dataPoints, this.x, this.y);
    }

    this.drawSlider();
    this.legends.sort((a, b) => +a.date - +b.date); // + are used to cast moment in number
  }

  public drawCircles(
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
    index: number,
    dataPoints: { value: number; minutesAfterMidnight: number }[],
    x: d3.ScaleLinear<number, number, never> | d3.ScaleTime<number, number, never>,
    y: d3.ScaleLinear<number, number, never>
  ): void {
    const circles = svg
      .selectAll(`.circle-${index}`)
      .data(dataPoints)
      .enter()
      .append("circle")
      .attr("class", (d) => `circle-${index} circle-minute-${d.minutesAfterMidnight}`)
      .attr("fill", this.colorPalette[index])
      .attr("stroke", "none")
      .attr("cx", (d) => {
        return x(d.minutesAfterMidnight);
      })
      .attr("cy", (d) => {
        return y(d.value);
      })
      .attr("r", 2)
      .attr("data-color", this.colorPalette[index]);
    circles.raise();
  }

  public drawSlider(): void {
    // The style of the slider are set in comunicare-custom.scss due to scope limitation with svg

    this.sliderTrack = this.svg
      .append("line")
      .attr("class", "slider-track")
      .attr("x1", this.margin)
      .attr("x2", this.width - this.margin)
      .attr("y1", this.height + 10) // place it at the bottom of the SVG
      .attr("y2", this.height + 10)
      .on("click", this.onClick);

    this.sliderHandle = this.svg
      .append("circle")
      .attr("class", "slider-handle") // Add unique class for the slider handle
      .attr("cx", this.margin) // start at the left edge of the slider track
      .attr("cy", this.height + 10) // align with the slider track
      .call(
        d3
          .drag()
          .on("start", () => this.activateSlider()) // Handle start of interaction
          .on("drag", this.onDrag) // attach drag behavior
      );

    // Listen for clicks outside the SVG to deactivate the slider
    d3.select("body").on("click", (event: MouseEvent) => this.deactivateSlider(event));
  }

  public onDrag(event: DragEvent): void {
    this.updateSliderPosition(event.x);
  }

  public onClick(event: MouseEvent): void {
    this.activateSlider();
    // Get the X position relative to the SVG
    const [newX] = d3.pointer(event, this.svg.node());
    this.updateSliderPosition(newX);
  }

  /**
   * Updates the slider position and updates the chart based on the given X position
   */
  private updateSliderPosition(newX: number): void {
    // Clamp the X position within the slider's range
    const clampedX = Math.max(this.margin, Math.min(this.width - this.margin, newX));
    // Move the slider handle to the new position
    this.sliderHandle.attr("cx", clampedX);
    this.selectedMinute = <number>this.x.invert(clampedX);
    this.updateChartForMinute();
  }

  public activateSlider(): void {
    this.sliderTrack.classed("slider-track-active", true); // Add the class for halo
    this.sliderHandle.classed("slider-handle-active", true);

    d3.select("body").on("keydown", (event: KeyboardEvent) => this.onKeyDown(event));
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (!this.sliderHandle.classed("slider-handle-active")) return; // Only if slider is active

    // const stepSize = this.x(1) - this.x(0); // Determine how much to move based on x-axis scaling
    const stepSize = 1;
    let newX = Math.round(+this.sliderHandle.attr("cx"));

    if (event.key === "ArrowLeft") {
      newX -= stepSize; // Move left
      event.preventDefault();
    } else if (event.key === "ArrowRight") {
      newX += stepSize; // Move right
      event.preventDefault();
    }
    this.updateSliderPosition(newX); // Use the existing method to update the slider and chart
  }

  public deactivateSlider(event: MouseEvent): void {
    const isClickOnSliderTrack = event.target === this.sliderTrack.node();
    const isClickOnSliderHandle = event.target === this.sliderHandle.node();

    if (!isClickOnSliderTrack && !isClickOnSliderHandle) {
      this.sliderTrack.classed("slider-track-active", false); // Remove halo when clicking outside
      this.sliderHandle.classed("slider-handle-active", false);

      this.legends.forEach((element) => {
        delete element.currentValue;
      });
      this.svg
        ?.selectAll("circle:not(.slider-handle)")
        .attr("r", 2)
        .attr("fill", function () {
          return d3.select(this).attr("data-color");
        });
    }
  }

  public updateChartForMinute(): void {
    // Find the closest minute in the data
    const allMinutes = this.data.flatMap((d) => d.values.map((v) => v.minutesAfterMidnight));
    const closestMinute = allMinutes.reduce((prev, curr) =>
      Math.abs(curr - this.selectedMinute) < Math.abs(prev - this.selectedMinute) ? curr : prev
    );

    // Reset all data circle sizes to default, excluding the slider handle
    this.svg?.selectAll("circle:not(.slider-handle)").attr("r", 2).attr("fill", "gray"); // Reset all circles to default radius

    // Select and enlarge circles that match the closest minute
    this.svg
      ?.selectAll(`.circle-minute-${closestMinute}`)
      .attr("r", 5)
      .attr("fill", function () {
        return d3.select(this).attr("data-color");
      });

    this.updateSelectedPointsLegend(closestMinute);
  }

  public updateSelectedPointsLegend(closestMinute: number): void {
    this.legends.forEach((legend) => {
      // Find the circle for the closest minute and corresponding color
      const selectedCircle = this.svg.select(`.circle-minute-${closestMinute}[data-color='${legend.color}']`);

      if (selectedCircle.empty()) {
        // If no circle matches for this color, remove the value from the legend
        legend.currentValue = undefined;
      } else {
        // If a matching circle exists, update the legend with the value
        selectedCircle.each(function (d: { value: number; minutesAfterMidnight: number }) {
          legend.currentValue = d.value;
        });
      }
    });
  }
}
