import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, of } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PhotoBoothDialogComponent } from "src/app/components/photo-booth-dialog/photo-booth-dialog.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { Tools } from "src/app/helpers/tools";
import { ICareplan } from "src/app/models/careplans.interface";
import { ActionStatusEntity } from "src/app/models/entity.interface";
import { IObservation, IObservationDefinition } from "src/app/models/observations.interface";
import { Observation } from "src/app/models/observations.model";
import { IReference } from "src/app/models/sharedInterfaces";
import { CareplansService } from "src/app/providers/careplans.service";
import { ResponsiveService } from "src/app/providers/responsive.service";

interface IObsAndDef {
  observation: IObservation;
  definition: IObservationDefinition;
}

@Component({
  selector: "app-encode-observations",
  templateUrl: "./encode-observations.component.html",
  styleUrls: ["./encode-observations.component.scss"],
})
/**
 * Important: for the changes in this component to be transmitted to his parent,
 * the parent need to call the "save()" method. No input will be modified by this
 * component.
 *
 * This component encode several observations.
 */
export class EncodeObservationsComponent implements OnInit, OnDestroy {
  // In case of editing, those will be the observations previously encoded:
  @Input() existingObservations: IObservation[];
  // The list of observations definitions we want to create observations for:
  @Input() observationsDefinitions: IObservationDefinition[];
  // The patient the observations are for:
  @Input() patientReference: IReference;
  // The potential questionnaire the observations are linked to:
  @Input() questionnaireIdentifier: string;
  // Whether or not to disabled the form (display only)
  @Input() disabled: boolean;
  // The method that will receive the newly created/edited list of observations
  @Output() newObservationsChange: EventEmitter<IObservation[]> = new EventEmitter<IObservation[]>();

  @ViewChild("input") importInput: ElementRef;
  public viewerOptions = {
    navbar: false,
    title: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: "large",
      },
      zoomOut: {
        show: 1,
        size: "large",
      },
      oneToOne: {
        show: 1,
        size: "large",
      },
      reset: {
        show: 1,
        size: "large",
      },
      prev: 0,
      play: 0,
      next: 0,
      rotateLeft: {
        show: 1,
        size: "large",
      },
      rotateRight: {
        show: 1,
        size: "large",
      },
      flipHorizontal: {
        show: 1,
        size: "large",
      },
      flipVertical: {
        show: 1,
        size: "large",
      },
    },
  };

  public newObservations: IObsAndDef[] = [];
  public patientCareplans: Observable<ICareplan[]>;
  private onDestroy$ = new Subject<void>();
  public isMobile: boolean;

  constructor(
    private translateService: TranslateService,
    private careplanService: CareplansService,
    private dialog: MatDialog,
    private responsiveService: ResponsiveService
  ) {
    this.responsiveService.isHandset$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => (this.isMobile = value));
  }

  ngOnInit(): void {
    // Get the careplans for the enableWhens:
    this.patientCareplans = this.patientReference
      ? this.careplanService.list(this.patientReference.reference).pipe(takeUntil(this.onDestroy$))
      : of([]);
    // List of definitions we want observations for:
    const obsDefs = [...this.observationsDefinitions];
    // In case of edit, prepare the existing observations for edition:
    if (this.existingObservations?.length) {
      const obs = Tools.clone(this.existingObservations);
      // Search for their definition in the list:
      for (const o of obs) {
        o.actionStatus = ActionStatusEntity.MODIFIED;
        const foundCorrespondingDefIdx = obsDefs.findIndex((d) => d.loinc === o.code.coding[0].code);
        if (foundCorrespondingDefIdx === -1) {
          FileLogger.warn("EncodeObservationsComponent", "Trying to update an observation without corresponding definition", o, "none");
        }
        this.newObservations.push({
          observation: o,
          definition: foundCorrespondingDefIdx > -1 ? obsDefs[foundCorrespondingDefIdx] : null,
        });
        // Remove the definition from the list
        if (foundCorrespondingDefIdx > -1) {
          obsDefs.splice(foundCorrespondingDefIdx, 1);
        }
      }
    }
    // Add empty observations for the observations definition that don't have a corresponding existing observation
    // And in case of create, create empty observations for all the definitions:
    for (const def of obsDefs) {
      this.newObservations.push({
        observation: Observation.createObservation(
          def,
          this.translateService.currentLang,
          this.patientReference,
          this.questionnaireIdentifier
        ),
        definition: def,
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Emit the new observations. Some of them can be empty.
   */
  public save(): void {
    this.newObservationsChange.emit(this.newObservations.map((o) => o.observation));
  }

  public clearObservation(observation: IObservation): void {
    for (const component of observation.component) {
      component.valueQuantity.value = null;
    }
  }

  public selectPhoto(): void {
    this.importInput.nativeElement.value = ""; // Otherwise, the same file can't be selected again.
    this.importInput.nativeElement.click();
  }

  public addPhoto(event: { target: { files: FileList } }, jindex: number, index: number): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Picture = reader.result as string;
      if (file) {
        if (base64Picture) {
          this.addPictureToObservationComponent(jindex, index, base64Picture);
        }
      } else {
        throw new Error("no file selected");
      }
    };
    reader.readAsDataURL(file);
  }

  private addPictureToObservationComponent(jindex: number, index: number, base64Picture: string): void {
    if (this.newObservations[jindex].observation.component[index].valuePictures) {
      this.newObservations[jindex].observation.component[index].valuePictures.push(base64Picture);
    } else {
      this.newObservations[jindex].observation.component[index].valuePictures = [base64Picture];
    }
  }

  public deletePhoto(iObs: number, iComponent: number, iPicture: number): void {
    this.newObservations[iObs].observation.component[iComponent].valuePictures.splice(iPicture, 1);
  }

  takePhoto(jindex: number, index: number): void {
    const dialog = this.dialog.open(PhotoBoothDialogComponent, {
      panelClass: "photo-booth-dialog",
      height: this.isMobile ? "100dvh" : "",
      width: this.isMobile ? "100vw" : "",
      minWidth: this.isMobile ? "100vw" : "",
      maxWidth: this.isMobile ? "100vw" : "",
      data: {},
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.addPictureToObservationComponent(jindex, index, result);
      }
    });
  }
}
