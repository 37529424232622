import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import uuid from "uuid-random";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { ObservationHelper } from "../helpers/observationHelper";
import { Tools } from "../helpers/tools";
import { IQuantity } from "./careplans.interface";
import { Codes } from "./codes.interface";
import { ActionStatusEntity, StatusEntity } from "./entity.interface";
import { Identifier } from "./identifier.interface";
import {
  IEffectiveTiming,
  ILinkedRuleAlert,
  IObservation,
  IObservationDefinition,
  IObservationWithoutComponents,
  OComponent,
} from "./observations.interface";
import { Reference } from "./reference.interface";
import { AccessLevel, IReference } from "./sharedInterfaces";

@Pipe({ name: "valueObservation" })
export class ValueObservationPipe implements PipeTransform {
  transform(headerCompo: OComponent, component: OComponent[]): number | null {
    let value: number;
    for (const compo of component) {
      if (
        compo.parentObservation?.code.coding[0].code === headerCompo.parentObservation?.code.coding[0].code &&
        compo.code.coding[0].code === headerCompo.code.coding[0].code
      ) {
        value = Tools.isDefined(compo.valueQuantity.value) ? Number(compo.valueQuantity.value) : null; // check if defined because Number(null) === 0
        break;
      }
    }
    // not found
    return !Tools.isDefined(value) || isNaN(value) ? null : value; // check if defined because isNaN(null) === false, null IS a number for javascript
  }
}

/**
 * Helper for FHIR Observation
 */
export class Observation implements IObservation {
  public resourceType: string;
  public identifier: Identifier[];
  public status: string;
  public category: Codes;
  public code: Codes;
  public subject: Reference;
  public encounter?: Reference;
  public issued: string;
  public performer?: Reference[];
  public comments?: string;
  public component: OComponent[];
  public effectiveTiming: IEffectiveTiming;
  public device?: {
    reference: string;
    type: string;
    componentAnswer: {
      loinc: string;
    }[];
  };
  public normColor?: string;
  public linkedRuleAlerts?: ILinkedRuleAlert[];
  public questionnaireResponseRef?: string;
  public patientAccess?: AccessLevel;

  constructor(data: IObservation) {
    this.resourceType = data.resourceType;
    this.identifier = data.identifier;
    this.status = data.status;
    this.category = data.category;
    this.code = data.code;
    this.subject = data.subject;
    this.encounter = data.encounter;
    this.issued = data.issued;
    this.performer = data.performer;
    this.comments = data.comments;
    this.component = data.component;
    this.effectiveTiming = data.effectiveTiming;
    this.device = data.device;
    this.normColor = data.normColor;
    this.linkedRuleAlerts = data.linkedRuleAlerts;
    this.questionnaireResponseRef = data.questionnaireResponseRef;
    this.patientAccess = data.patientAccess;
  }

  /**
   * get Value of this observation
   *  @observation
   *  @code
   */
  public getValue(code: string): number {
    let value: number;
    for (const compo of this.component) {
      const ignoreSuffix = ObservationHelper.ignoreSuffix(compo.code.coding[0].code);
      if (ignoreSuffix === ObservationHelper.ignoreSuffix(code)) {
        // !Number(null) === 0 in javascript, 0 IS a number
        value = Tools.isDefined(compo.valueQuantity.value) ? Number(compo.valueQuantity.value) : null;
        break;
      }
    }
    // !Number(null) === 0 in javascript, 0 IS a number
    const result = !Tools.isDefined(value) || isNaN(value) ? null : value;
    return result;
  }
  /**
   * get Value of this observation
   *  @observation
   *  @code
   */
  public getValueWithSuffix(code: string): number {
    let value: number;
    for (const compo of this.component) {
      const compLoinc = compo.code.coding[0].code;
      if (compLoinc === code) {
        // !Number(null) === 0 in javascript, 0 IS a number
        value = Tools.isDefined(compo.valueQuantity.value) ? Number(compo.valueQuantity.value) : null;
        break;
      }
    }
    // !Number(null) === 0 in javascript, 0 IS a number
    const result = !Tools.isDefined(value) || isNaN(value) ? null : value;
    return result;
  }

  /**
   * get effectiveTiming code of this observation
   */
  public get effectiveTimingCode(): string {
    return this.effectiveTiming?.repeat?.when?.code;
  }

  /**
   * get Value of this observation
   *  @observation
   *  @display
   */
  public getValueWithDisplay(display: string): number | null {
    for (const compo of this.component) {
      if (compo.code.coding[0].display === display) {
        const value = Number(compo.valueQuantity.value);
        return isNaN(value) ? null : value;
      }
    }
    // not found
    return null;
  }

  /**
   * get main display of this observation
   *  @observation
   *  @code
   */
  public get mainDisplay(): string | null {
    if (this.code?.coding && this.code.coding.length) {
      return this.code.coding[0].display;
    }
    // not found
    return null;
  }

  public get loinc(): string | null {
    if (this.code?.coding && this.code.coding.length) {
      return this.code.coding[0].code;
    }
    // not found
    return null;
  }

  /**
   * return understable unit or meaning for an observation;
   */
  public getMeaning(definitions: IObservationDefinition[], lang: string): string | null {
    const def = definitions.find((d) => d.loinc === this.loinc);
    if (def) {
      const cibledComponent = def.components.find((c) => c.loinc === this.loinc);
      if (cibledComponent) {
        if (cibledComponent.meaning) {
          const cibledMeaning = cibledComponent.meaning.find((m) => m.value === this.getValue(this.loinc));
          return Tools.getTranslation(cibledMeaning?.description, lang, "");
        } else if (cibledComponent.unit) {
          return cibledComponent.unit;
        } else {
          return "-";
        }
      }
    }
    return null;
  }
  public static createObservation(
    definition: IObservationDefinition,
    lang: string,
    patientRef: IReference,
    questionnaireResponseRef?: string
  ): IObservation {
    return {
      _id: undefined,
      actionStatus: ActionStatusEntity.CREATED,
      entityStatus: [StatusEntity.ACTIVE],
      issued: moment().format(),
      modified: null,

      // "bodySite": null,
      category: {
        // TODO the category is probably not always the same.
        coding: [
          {
            code: "vital-signs",
            display: "Vital Signs",
            system: "http://hl7.org/fhir/observation-category",
          },
        ],
      },
      code: {
        coding: [
          {
            code: definition.loinc,
            display: definition.nameTranslation
              ? definition.nameTranslation[lang]
              : definition.name
              ? definition.name[0]
              : definition.loinc,
            system: "http://loinc.org",
          },
        ],
      },
      comments: null,
      component: definition.components.map((component) => ({
        code: {
          coding: [
            {
              code: component.loinc || definition.loinc,
              display: component.nameTranslation
                ? component.nameTranslation[lang]
                : component.name
                ? component.name[0]
                : component.loinc || definition.nameTranslation
                ? definition.nameTranslation[lang]
                : definition.name
                ? definition.name[0]
                : definition.loinc,
              system: "http://loinc.org",
            },
          ],
        },
        valueQuantity: {
          code: null,
          system: null,
          unit: component.unit || null,
          value: null,
        },
      })),
      encounter: null,
      identifier: [
        {
          use: FHIRHelper.USUAL,
          system: FHIRHelper.SYSTEM_COMUNICARE,
          value: "observation/" + uuid(),
          label: FHIRHelper.SYSTEM_COMUNICARE,
        },
      ],
      performer: null,
      resourceType: "Observation",
      status: "final",
      subject: patientRef,
      questionnaireResponseRef,
      patientAccess: definition.patientDefaultAccess,
    };
  }
}

export class ObsComponent implements OComponent {
  public code: Codes;
  public valueQuantity: IQuantity;
  public effectiveTiming?;
  public valuePictures?: string[];
  public parentObservation?: IObservationWithoutComponents;
  constructor(data: OComponent) {
    this.code = data.code;
    this.valueQuantity = data.valueQuantity;
    this.effectiveTiming = data.effectiveTiming;
    this.valuePictures = data.valuePictures;
    this.parentObservation = data.parentObservation;
  }

  /**
   * get effectiveTiming code of the component
   */
  public get effectiveTimingCode(): string {
    return this.effectiveTiming?.repeat?.when?.code;
  }
}
