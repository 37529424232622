import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, forkJoin, throwError } from "rxjs";
import { catchError, first, map, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { ChartHelper, xAxisType, yAxisType } from "src/app/helpers/chart-helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { HelpData } from "src/app/helpers/helpData";
import { ObservationHelper } from "src/app/helpers/observationHelper";
import { Tools } from "src/app/helpers/tools";
import { AppError } from "src/app/models/app-error.interface";
import { ChartSettings } from "src/app/models/chart.interface";
import { IPDFOptions } from "src/app/models/export.interface";
import { IExternalRessource } from "src/app/models/externalResource.interface";
import { IObservationDefinition, OBSERVATION_VIEW, ObservationResume, OComponent } from "src/app/models/observations.interface";
import { Observation } from "src/app/models/observations.model";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { ObservationsService } from "src/app/providers/observations.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { ToolsService } from "src/app/providers/tools.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { StreamObsChartComponent } from "../charts/stream-obs-chart/stream-obs-chart.component";
import { Item } from "../item-selector/item-selector.component";
import { ObservationChartComponent } from "../observation-chart/observation-chart.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { MomentObservationTableComponent } from "./moment-observation-table/moment-observation-table.component";
import { ObservationDefinitionListComponent } from "./observation-definition-list/observation-definition-list.component";
import { TranslateComponentPipe } from "./patient-observations-pipes/translate-component.pipe";

@Component({
  selector: "app-patient-observations",
  templateUrl: "./patient-observations.component.html",
  styleUrls: ["./patient-observations.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientObservationsComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
  @ViewChild("momentObsTableCmpt") momentObsTableCmpt: MomentObservationTableComponent;
  @ViewChild(StreamObsChartComponent) streamObsChartCmpt: StreamObsChartComponent;
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier && pu.user.caremateIdentifier !== this.pu?.user.caremateIdentifier) {
      this.availableObservations = [];
      this.pu = pu;

      // if showStreamObs was true, we need to reset it because we don't know if new selected user has some
      if (this.showStreamObs === true) {
        this.showStreamObs = false;
        this.streamObs = [];
        this.fromDate = moment().subtract(3, "month");
        this.toDate = moment().endOf("day");
        this.maxFromDate = this.toDate;
        this.minToDate = this.fromDate;
        this.updateDateForm();
      }

      this.initDataAndAutoRefresh(pu.user.caremateIdentifier, this.preferenceService.getAutoRefreshWidget());
    }
  }
  @ViewChild(ObservationChartComponent) observationChart: ObservationChartComponent;
  @ViewChild(StreamObsChartComponent) streamObsChart: StreamObsChartComponent;
  @ViewChild("focus") target: ElementRef;

  private isBig$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public scrollAfterDataInit = false;
  public minToDate: moment.Moment = null;
  public maxFromDate: moment.Moment = null;
  public pu: PatientUser;
  public allObs: Observation[] = [];
  public streamObs: string[];
  public hasStreamData: boolean;
  public isBig = false;
  public aggregateByDay = true;
  public activateNorms = false;
  public showMomentView = false;
  public availableObservations: ObservationResume[] = [];
  public sliderData: Item[] = [];
  public optData: Item[] = [
    {
      value: "axeYchangePosition",
      display: this.translateService.instant("graph.axeYchangePosition"),
      checked: false,
    },
    /* SEE CMATE-1301
    {
      value: 'bloodPressureShownMethod',
      display: this.translateService.instant('graph.bloodPressureShownMethod'),
      checked: false
      }*/
  ];
  public allDefinitions: IObservationDefinition[] = [];
  public currentDefinitions: IObservationDefinition[] = [];
  public headers: OComponent[] = [];
  public mergedObservations: Observation[];
  public chartAndExportObs: Observation[]; // Fixed version of the original Observations to make sure we can use it in charts, export and in alert history.
  public chartSettings: ChartSettings = {
    axeYchangePosition: false,
    // bloodPressureShownMethod: false
  };
  public filterFormGraph = this.fb.group({
    fromDate: ["", [Validators.required]],
    toDate: ["", [Validators.required]],
  });
  public filterFormTable = this.fb.group({
    fromDate: ["", [Validators.required]],
    toDate: ["", [Validators.required]],
  });

  public fromDate: moment.Moment;
  public toDate: moment.Moment;
  public filteredMergedObs: Observation[];
  public isNoneObservations = true;

  public options = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    headers: [],
    showTitle: false,
    title: "",
    useBom: true,
    removeNewLines: true,
    useKeysAsHeaders: false,
  };
  public loading = true;
  private refreshInterval;
  public hasImages = false;

  public showStreamObs = false;
  public streamObsExternalResourcesList: IExternalRessource[] = [];
  public selectedExternalResourceRef: string;

  public exportPdfInProgress: boolean;
  public exportCsvInProgress: boolean;

  public hasDefinitions$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public actions: WidgetActionConfig[] = [
    {
      type: "button",
      icon: "settings",
      label: "btn.settings",
      ariaLabel: "icon button with a settings icon",
      condition$: this.hasDefinitions$,
      action: (): unknown => this.onAddObservations(),
    },
    {
      type: "button",
      icon: "open_in_full",
      label: "btn.details",
      ariaLabel: "icon button with a plus icon",
      condition$: combineLatest([this.isBig$, this.isMobile$]).pipe(map(([isBig, isMobile]) => !isBig && !isMobile)),
      action: (): unknown => this.onPlus(),
    },
    {
      type: "button",
      icon: "close_fullscreen",
      label: "btn.reduce",
      ariaLabel: "icon button with a pencil icon",
      condition$: this.isBig$,
      action: (): unknown => this.onPlus(),
    },
  ];

  OBSERVATION_VIEW = OBSERVATION_VIEW;
  activeView: OBSERVATION_VIEW = OBSERVATION_VIEW.HOURLY;

  constructor(
    private observationsService: ObservationsService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    private statService: UserStatisticsService,
    private fb: UntypedFormBuilder,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    public helpData: HelpData,
    private toolsService: ToolsService,
    private snackBar: MatSnackBar,
    private tileManager: TileManager,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    protected responsiveService: ResponsiveService
  ) {
    super(responsiveService);
  }

  ngOnInit(): void {
    this.fromDate = moment().subtract(3, "month");
    this.toDate = moment().endOf("day");
    this.maxFromDate = this.toDate;
    this.minToDate = this.fromDate;
    this.updateDateForm();
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // the idea is to make the pipes realize there's something that changed without
      // restarting everything. For that we just need to 'change' one of the
      // pipe variables.
      if (this.allDefinitions) {
        // Cloning will make it appear as if this is a new object
        this.allDefinitions = Tools.clone(this.allDefinitions);
      }
    });
    this.sessionService.refreshObservationsList.subscribe(() => {
      if (this.pu) {
        this.initData(this.pu.user.caremateIdentifier);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearAutoRefresh();
  }

  private refreshObs(userId: string, fromDate?: string, toDate?: string): void {
    this.observationsService
      .list(userId, fromDate, toDate)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((obs) => {
        this.allObs = obs;
        this.updateDateForm();
        this.updateDefinitionDate();
      });
  }

  private initDataAndAutoRefresh(userId: string, _autoRefreshIntervalMs = 60000): void {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.initData(userId); }, autoRefreshIntervalMs);
    this.initData(userId);
  }

  private clearAutoRefresh(): void {
    clearInterval(this.refreshInterval);
  }

  private initData(userId: string): void {
    if (!userId) return;
    this.loading = true;
    const obsObservations = this.observationsService
      .list(userId, this.fromDate?.toISOString() ?? undefined, this.toDate?.toISOString() ?? undefined)
      .pipe(
        catchError((err) => {
          FileLogger.error("patient-observations - initData", "Error listing obsObservations");
          return throwError(err);
        }),
        takeUntil(this.onDestroy$)
      );
    const obsDef = this.observationsService.listDef(userId).pipe(
      catchError((err) => {
        FileLogger.error("patient-observations - initData", "Error listing obsDef");
        return throwError(err);
      }),
      takeUntil(this.onDestroy$)
    );
    const streamObs$ = this.observationsService.listStream(userId).pipe(
      catchError((err) => {
        FileLogger.error("patient-observations - initData", "Error listing streamObs");
        return throwError(err);
      }),
      takeUntil(this.onDestroy$)
    );
    const devicesObs$ = this.observationsService.listStreamObservationsDevices(this.pu.user.caremateIdentifier).pipe(
      catchError((err) => {
        FileLogger.error("patient-observations - initData", "Error listing devicesObs");
        return throwError(err);
      }),
      takeUntil(this.onDestroy$)
    );

    forkJoin([obsObservations, obsDef, streamObs$, devicesObs$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([allObs, allDef, streamObs, devicesObs]) => {
          this.allObs = allObs;
          this.allDefinitions = allDef;
          this.streamObsExternalResourcesList = devicesObs;
          this.hasDefinitions$.next(this.allDefinitions.length ? true : false);
          this.streamObs = streamObs;
          this.setupPatientDevices();
          this.sliderData = this.computeSliderData();
          this.currentDefinitions = this.computeDefinitions(allDef).filter(
            (def) => this.sliderData.find((sd) => def?.loinc === sd.value)?.checked
          );
          this.availableObservations = [];
          this.loadPreferences();
        },
        (err) => {
          FileLogger.error("PatientObservationsComponent", "Error while downloading observations: ", err);
          this.loading = false;
        }
      );
  }
  private computeSliderData() {
    let sliderData = this.allDefinitions.map((def) => ({
      value: def?.loinc,
      display: Tools.getTranslation(def?.nameTranslation, this.sessionService.userLang, def?.loinc),
      checked: true,
    }));
    // some items are in double
    sliderData.filter((obj, index) => {
      return (
        index ===
        sliderData.findIndex((objSecond) => {
          return Tools.isEqual(obj, objSecond);
        })
      );
    });

    if (this.showStreamObs) {
      sliderData = sliderData.filter((el) => this.streamObs.includes(el.value));
    } else {
      sliderData = sliderData.filter((el) => !this.streamObs.includes(el.value));
    }
    return sliderData;
  }

  private loadData(): void {
    if (!this.availableObservations || this.availableObservations.length === 0) {
      this.computeAllObservations();
    }
    if (this.isBig) {
      if (this.activateNorms) {
        this.loadObsWithNorms();
      } else {
        this.computeMergedObservations();
        this.computeHeaders();
      }
    }
  }

  private computeAllObservations(): void {
    this.availableObservations = [];
    this.allObs.forEach((obs) =>
      obs.component.forEach((obsComponent) => {
        if (obsComponent?.code?.coding && obsComponent.code.coding.length) {
          // prepare all availableObservations
          this.availableObservations.push({
            // problem
            reference: obsComponent.code.coding[0].code,
            // we will need the parent's code to find the right definition:
            parentReference: obs.code.coding[0].code,
            display: obsComponent.code.coding[0].display,
            date: obs.issued,
            value: obsComponent.valueQuantity.value,
            unit: obsComponent.valueQuantity.unit,
            pictures: obsComponent.valuePictures,
            device: obs.device,
          });
        }
      })
    );
  }

  public setupPatientDevices(): void {
    if (this.streamObsExternalResourcesList?.length && !this.selectedExternalResourceRef) {
      this.selectedExternalResourceRef = this.streamObsExternalResourcesList[0].reference;
      if (this.streamObs?.length) {
        this.streamObs = this.streamObs.filter((o) => this.streamObsExternalResourcesList[0].meta?.availableLoinc?.includes(o));
        this.onShowStreamChange();
      }
    } else if (this.streamObsExternalResourcesList?.length && this.selectedExternalResourceRef) {
      if (this.streamObs?.length) {
        this.streamObs = this.streamObs.filter((o) => this.streamObsExternalResourcesList[0].meta?.availableLoinc?.includes(o));
        this.onShowStreamChange();
      }
    } else {
      this.selectedExternalResourceRef = undefined;
    }
  }

  public changeFilter(event: Item[]): void {
    // copy as not reference and map to create observation object
    this.filteredMergedObs = Tools.clone(this.mergedObservations).map((o) => new Observation(o));
    this.sliderData = Tools.clone(event); // Force object reference update to trigger pipe
    this.computeHeaders();
    this.currentDefinitions = this.computeDefinitions(this.allDefinitions).filter(
      (def) => this.sliderData.find((sd) => def?.loinc === sd.value)?.checked
    );
    // first filter (delete obs component not checked in checkbox)
    this.filteredMergedObs.forEach((obs) => {
      obs.component = obs.component.filter(
        (comp) =>
          this.sliderData.find(
            (sd) =>
              ObservationHelper.ignoreSuffix(comp.code.coding[0].code) === sd.value ||
              comp.parentObservation?.code.coding[0].code === sd.value
          )?.checked
      );
    });

    // second filter (clean obs where no component are present)
    this.filteredMergedObs = this.filteredMergedObs.filter((obs) => obs.component.length);
    this.refreshObs(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString());
  }

  private computeDefinitions(obsDef: IObservationDefinition[]) {
    const definitions: IObservationDefinition[] = [];

    const noDuplicatedObsObservations = this.allObs.reduce((listObs, observation) => {
      const exists = listObs.findIndex((o) => o.code?.coding[0].code === observation.code?.coding[0].code);
      if (exists < 0) {
        listObs.push(observation);
      }
      return listObs;
    }, [] as Observation[]);

    if (noDuplicatedObsObservations) {
      noDuplicatedObsObservations.forEach((observation: Observation) => {
        const definition = obsDef.find((def: IObservationDefinition) => observation.code?.coding[0]?.code === def.loinc);
        if (definition) {
          definitions.push(definition);
        }
      });
    }
    return definitions;
  }

  public computeHeaders(): void {
    if (!this.mergedObservations) {
      this.headers = [];
    }
    const noDuplicatedComponent = this.mergedObservations.reduce((headers, observation) => {
      observation.component.forEach((component) => {
        const exists = headers.findIndex(
          (c) =>
            c.code?.coding[0].code === component.code?.coding[0].code &&
            c.parentObservation?.code?.coding[0].code === component.parentObservation?.code?.coding[0].code
        );
        if (exists < 0) {
          headers.push(component);
        }
      });
      return headers;
    }, [] as OComponent[]);
    this.headers = noDuplicatedComponent.filter((o) => {
      if (this.sliderData.length <= 0) {
        return true;
      } else {
        // we need to compare to the parent loinc code (and not the one of the component) to find the right definition
        const definition = this.allDefinitions.find((def) => o.parentObservation?.code?.coding[0]?.code === def.loinc);

        if (definition) {
          return this.sliderData.find((sd) => sd.value === definition.loinc)?.checked;
        } else {
          return false;
        }
      }
    });
  }

  public computeMergedObservations(): void {
    this.mergedObservations = [];
    let charObs = [];

    // clone object to avoid working on a shallow copy
    this.allObs.forEach((obs) => {
      this.mergedObservations.push(new Observation(Tools.clone(obs)));
      charObs.push(new Observation(Tools.clone(obs)));
    });
    // Make sure to fix the array of obs to show in the exported csv and the chart.
    charObs = charObs
      .sort((a, b) => (moment(a.issued).isAfter(moment(b.issued)) ? -1 : 1))
      .filter((o) => moment(o.issued).isBetween(moment(this.fromDate), moment(this.toDate), "day", "[]"));
    this.chartAndExportObs = charObs;

    // copy effectiveTiming from parent observation to children components
    this.mergedObservations.forEach((observation: Observation) => {
      observation.component.forEach((component) => {
        component.effectiveTiming = observation.effectiveTiming;
      });
    });

    if (this.aggregateByDay && this.activeView === OBSERVATION_VIEW.DEFAULT) {
      this.mergedObservations = this.mergedObservations.reduce((mergedObs, observation, _i) => {
        const index = mergedObs.map((o) => moment(o.issued).isSame(moment(observation.issued), "day")).lastIndexOf(true);
        const existing = mergedObs[index];
        if (!existing) {
          // if the day of the observation is not yet in the table, create a new row (by pushing the observation)
          mergedObs.push(observation); // add row
        } else if (observation.component) {
          if (existing.component) {
            for (const comp of observation.component) {
              // Check if the specific observation has already been made and create a new row in the table if it has.
              // We need the parent code to properly identify a component!
              const sameComponent = existing.component.find(
                (c) =>
                  c.code.coding[0].code === comp.code.coding[0].code &&
                  c.parentObservation?.code.coding[0].code === comp.parentObservation?.code.coding[0].code
              );
              if (sameComponent) {
                mergedObs.push(observation); // add row
                break;
                // if not, just add the component to the existing row
              } else {
                existing.component.push(comp); // add column
              }
            }
          } else {
            existing.component = observation.component;
          }
        }
        return mergedObs;
      }, [] as Observation[]);
    }

    this.filteredMergedObs = this.mergedObservations
      .sort((a, b) => (moment(a.issued).isAfter(moment(b.issued)) ? -1 : 1))
      .filter((o) => moment(o.issued).isBetween(moment(this.fromDate), moment(this.toDate), "day", "[]"));
    if (this.sliderData && this.sliderData.length > 0) {
      // first filter (delete obs component not checked in checkbox)
      this.filteredMergedObs.forEach((obs) => {
        obs.component = obs.component.filter(
          (comp) =>
            this.sliderData.find(
              (sd) =>
                ObservationHelper.ignoreSuffix(comp.code.coding[0].code) === sd.value ||
                comp.parentObservation?.code.coding[0].code === sd.value
            )?.checked
        );
      });
      // second filter (clean obs where no component are present)
      this.filteredMergedObs = this.filteredMergedObs.filter((obs) => obs.component.length);
    }

    this.isNoneObservations = this.mergedObservations?.length === 0;
    this.updateHasImages();
  }

  /**
   * Preferences
   */
  public updatePreference(updateFocus: boolean): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_OBSERVATIONS_LIST,
        parameters: {
          isBig: this.isBig,
          settings: this.chartSettings,
          aggregateByDay: this.aggregateByDay,
          preferredView: this.activeView,
          activateNorms: this.activateNorms,
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isBig && updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.PATIENT_OBSERVATIONS_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (parameters: WidgetPatientParameter) => {
          if (parameters) {
            this.isBig = this.sessionService.globalPref?.keepLayoutFromPatientPage
              ? parameters.isBig
              : TileManager.isBig(PatientWidgetName.OBSERVATIONS);
            this.isBig$.next(this.isBig);
            this.tileManager.updateList(PatientWidgetName.OBSERVATIONS, this.isBig);
            if (this.isBig) {
              this.statService.createStatEvent("Look at observations in big mode");
            }

            if (parameters.settings) {
              this.chartSettings = {
                axeYchangePosition: parameters.settings.axeYchangePosition,
                // bloodPressureShownMethod: parameters.settings.bloodPressureShownMethod SEE CMATE-1301
              };
            }
            this.aggregateByDay = parameters.aggregateByDay;
            this.activateNorms = parameters.activateNorms;

            if (parameters.preferredView) {
              this.activeView = parameters.preferredView;
            }

            this.loadData();
            this.loading = false;
          }
          this.loading = false;
          if (this.scrollAfterDataInit && this.isBig && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
            this.scroll();
          }
        },
        (err) => {
          FileLogger.error("PatientObservationsComponent", "Error while downloading observations preferences: ", err);
          this.loading = false;
        }
      );
  }

  public onExport(): void {
    this.exportCsvInProgress = true;
    const obsCodes = this.showStreamObs
      ? this.streamObs
      : [...new Set(this.sliderData.filter((s) => s.checked === true).map((el) => el.value))];

    this.observationsService
      .exportObservations(
        this.pu.user.caremateIdentifier,
        this.fromDate.format("YYYY-MM-DD"),
        this.toDate.format("YYYY-MM-DD"),
        obsCodes,
        this.sessionService.userLang,
        "CSV"
      )
      .subscribe(
        (blob) => {
          const url = URL.createObjectURL(blob);
          Tools.downloadBlob(blob, url.split("/")[url.split("/").length - 1]);
        },
        (error: AppError) => {
          FileLogger.error("PatientObservationsComponent", "onExport()", error);
          throw new Error(error.message);
        },
        () => {
          this.exportCsvInProgress = false;
        }
      );

    this.statService.createStatEvent("Export observations to CSV");
  }

  public onPlus(): void {
    this.isBig = !this.isBig;
    this.isBig$.next(this.isBig);
    // if no data and go small retore fromDate
    if (!this.isBig && this.isNoneObservations) {
      this.dateChangeFrom(moment().subtract(3, "month"));
    }
    if (this.isBig) {
      this.statService.createStatEvent("Look at observations in big mode");
    }
    this.tileManager.updateList(PatientWidgetName.OBSERVATIONS, this.isBig);
    this.updatePreference(true);
    this.loadData();
    this.scroll();
  }

  public updateLastFocus(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.OBSERVATIONS,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  /**
   * TODO : when we move mouse after click, scrollIntoView seems to not work
   */
  private scroll(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }

  public dateChangeFrom(value: moment.Moment): void {
    // only request api if new from date is before previous cause we already have data
    this.minToDate = this.filterFormGraph.get("fromDate").value;
    if (value.isBefore(this.fromDate)) {
      this.fromDate = value;
      this.refreshObs(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString());
    } else {
      this.fromDate = value;
      this.updateDateForm();
      this.updateDefinitionDate();
    }
    this.cdr.detectChanges();
  }

  public dateChangeTo(value: moment.Moment): void {
    this.maxFromDate = this.filterFormGraph.get("toDate").value;
    this.toDate = value;
    this.updateDateForm();
    this.updateDefinitionDate();
    this.cdr.detectChanges();
  }

  private updateDefinitionDate(): void {
    this.loadData();
    if (this.sliderData && this.sliderData.length > 0) {
      this.currentDefinitions = this.computeDefinitions(this.allDefinitions).filter((def) => {
        return this.sliderData.find((sd) => {
          return def?.loinc === sd.value;
        })?.checked;
      });
    } else {
      this.currentDefinitions = this.computeDefinitions(this.allDefinitions);
    }
    this.updateHasImages();
  }

  private updateDateForm(): void {
    this.filterFormGraph.get("fromDate").setValue(this.fromDate);
    this.filterFormGraph.get("toDate").setValue(this.toDate);
    this.filterFormTable.get("fromDate").setValue(this.fromDate);
    this.filterFormTable.get("toDate").setValue(this.toDate);
  }

  public changeGraphSettings($event: Item[]): void {
    this.chartSettings = {
      axeYchangePosition: $event.find((i) => i.value === "axeYchangePosition")?.checked,
      // bloodPressureShownMethod: $event.find(i => i.value === 'bloodPressureShownMethod')?.checked
    };
    this.updatePreference(false);
    this.updateHasImages();
  }

  public openObservationHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientObservationsHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  public onExportPDF(): void {
    this.exportPdfInProgress = true;
    this.snackBar.open(this.translateService.instant("export.message"), "ok", { duration: 3000 });

    const title = `${this.translateService.instant("export.observation")} : ${this.datePipe.transform(
      this.fromDate.toISOString(),
      "shortDate"
    )} - ${this.datePipe.transform(this.toDate.toISOString(), "shortDate")}`;

    if (this.observationChart) {
      const content = '<img style="max-width: 98%;" src="' + this.observationChart.getImage() + '"/>';
      this.toolsService.createPFDAndOpenInBrowser(this.pu, title, content).then(() => {
        this.exportPdfInProgress = false;
      });
      this.statService.createStatEvent("Export observations to PDF");
    }

    if (this.streamObsChart) {
      const chartHtml = this.generateResizedChart();

      const pdfOptions: IPDFOptions = { margin: { top: 28, right: 28, bottom: 28, left: 28 } };
      const style = `
      html { -webkit-print-color-adjust: exact; }
      .legend-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
      }
      .legend-icon {
      height: 10px;
      width: 20px;
      }
      .legend-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      }`;

      this.toolsService.convertHtmlToPFDAndOpenInBrowser(this.pu, title, chartHtml.outerHTML, style, pdfOptions, true).then(() => {
        this.exportPdfInProgress = false;
      });
    }
  }

  public generateResizedChart(): HTMLDivElement {
    const legend = this.streamObsChartCmpt?.legend?.nativeElement;

    const chartConfig = Tools.clone(this.streamObsChartCmpt.chartConfig);
    chartConfig.width = 800;

    const container = document.createElement("div");
    const margin = chartConfig.margin;
    const width = chartConfig.width - margin * 2;
    const height = chartConfig.height - margin * 2;

    const svg = ChartHelper.createSvg(width, height, margin, container);
    const x = ChartHelper.createXAxis(<xAxisType>chartConfig.xAxis.type, margin, width, height, svg, chartConfig);
    const y = ChartHelper.createYAxis(<yAxisType>chartConfig.yAxis.type, margin, width, height, svg, chartConfig);

    for (let index = 0; index < this.streamObsChartCmpt.data.length; index++) {
      const d = this.streamObsChartCmpt.data[index];
      const dataPoints = d.values.map((el) => ({
        value: el.valueQuantity,
        minutesAfterMidnight: el.minutesAfterMidnight,
      }));
      this.streamObsChartCmpt.drawCircles(svg, index, dataPoints, x, y);
    }
    container.prepend(legend);
    return container;
  }

  public toggleAgregateByDay(): void {
    this.aggregateByDay = !this.aggregateByDay;
    this.computeMergedObservations();
    this.updatePreference(false);
  }

  public toggleActivateNorms(): void {
    this.activateNorms = !this.activateNorms;
    this.loadObsWithNorms();
    this.updatePreference(false);
  }

  private loadObsWithNorms() {
    this.observationsService
      .list(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString(), this.activateNorms)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((obs) => {
        this.allObs = obs;
        this.computeMergedObservations();
        this.computeHeaders();
      });
  }

  private getObsName(component: OComponent): string {
    const translateComponent = new TranslateComponentPipe(this.sessionService);
    return translateComponent.transform(component, this.allDefinitions);
  }

  private getFormatedPicturesTitle(obsIndex: number, compIndex: number) {
    return (
      this.getObsName(this.filteredMergedObs[obsIndex].component[compIndex]) +
      " - " +
      moment(this.filteredMergedObs[obsIndex].issued).format("DD/MM/YYYY") +
      " - " +
      this.filteredMergedObs[obsIndex].component[compIndex].valueQuantity.value +
      " " +
      (this.filteredMergedObs[obsIndex].component[compIndex].valueQuantity.unit
        ? this.filteredMergedObs[obsIndex].component[compIndex].valueQuantity.unit
        : "")
    );
  }

  private updateHasImages() {
    this.hasImages = false;
    this.filteredMergedObs?.forEach((obs) => {
      obs.component?.forEach((comp) => {
        if (comp.valuePictures?.length > 0) {
          this.hasImages = true;
        }
      });
    });
  }

  public onExportImage(): void {
    const title = this.translateService.instant("export.observationImage");
    const obsToShow: {
      pictures: string[];
      title: string;
    }[] = [];
    this.filteredMergedObs?.forEach((obs, iObs) => {
      obs.component?.forEach((comp, iComp) => {
        if (comp.valuePictures?.length > 0) {
          const pictures = [];
          comp.valuePictures.forEach((img) => {
            pictures.push(img);
          });
          obsToShow.push({
            pictures,
            title: this.getFormatedPicturesTitle(iObs, iComp),
          });
        }
      });
    });
    let content = "<div>";
    obsToShow.forEach((obs, i) => {
      // We use the css properties page-break-before / page-break-after to show one observation per page
      // we don't need it for the first page and the last page
      // we also want a break when there are more than 2 images in the first page otherwise it doesn't fit with the header of the pdf
      if ((i === 0 && obs.pictures.length < 3) || i === obsToShow.length - 1) {
        content += '<div style="width: 100%; text-align: center"><h3>' + obs.title + "</h3>";
      } else {
        content +=
          '<div style="page-break-before: always; page-break-after: always; width: 100%; text-align: center"><h3>' + obs.title + "</h3>";
      }
      obs.pictures.forEach((img) => {
        content += '<img style="max-width: 45%; max-height: 45%; margin: 1%; "src="' + img + '" />';
      });
      content += "</div>";
    });
    content += "</div>";

    this.toolsService.createPFDAndOpenInBrowser(this.pu, title, content);
    this.snackBar.open(this.translateService.instant("export.message"), "ok", { duration: 3000 });
  }

  public onAddObservations(): void {
    this.dialog.open(ObservationDefinitionListComponent, {
      data: {
        patientId: this.pu.user.caremateIdentifier,
        language: this.sessionService.userLang,
      },
      disableClose: true,
    });
  }

  public async onShowStreamChange(): Promise<void> {
    if (this.showStreamObs) {
      this.sliderData = this.computeSliderData();
      this.fromDate = moment().subtract(6, "day");
      this.toDate = moment().endOf("day");
      this.updateDateForm();
      this.currentDefinitions = this.allDefinitions.filter((def) => this.sliderData.find((sd) => def?.loinc === sd.value)?.checked);
    } else {
      this.sliderData = this.computeSliderData();
      this.fromDate = moment().subtract(3, "month");
      this.toDate = moment().endOf("day");
      this.refreshObs(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString());
    }
  }

  /** Update form when component ui-date-range-max modify the date range */
  onDateChange(): void {
    if (this.showStreamObs) {
      if (!this.toDate) {
        // avoid bug if no end date is selected in the intervalPicker
        this.toDate = this.fromDate;
      }
      this.updateDateForm();
      this.currentDefinitions = this.allDefinitions.filter((def) => this.sliderData.find((sd) => def?.loinc === sd.value)?.checked);
    } else {
      this.refreshObs(this.pu.user.caremateIdentifier, this.fromDate.toISOString(), this.toDate.toISOString());
    }
  }
}
