// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: "dev",
  production: false,
  apiUrl: "https://devsrv.comunicare.io/api",
  dashboardV1Url: "https://v1.devsrv.comunicare.io",
  optionsConnections: [1, 2, 3], // 1 = PASSWORD, 2 = EHEALTH, 3 = PSC
  maintenanceUrl: "https://devmaintenance.comunicare.be/free-api",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
