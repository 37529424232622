<div class="patientListPage" id="patientListPageContainer">
  <div class="toolbar">
    <div class="left">
      <form [formGroup]="formGroup">
        <!-- GLOBAL SEARCH -->
        <div *ngIf="country === FRANCE">
          <mat-form-field>
            <input
              type="text"
              matInput
              placeholder="{{ 'forms.ins' | translate }}"
              formControlName="ins"
              maxlength="15"
              minlength="15"
              [errorStateMatcher]="matcher"
              #searchINS
            />
            <mat-error *ngIf="!formGroup.get('ins').valid || formGroup.hasError('invalidIns')">
              {{ "forms.insError" | translate }}
            </mat-error>
          </mat-form-field>
          <button
            type="button"
            mat-icon-button
            aria-label="Clear"
            style="margin: 3px"
            type="button"
            (click)="
              searchINS.value = '';
              searchViaIdentifierNumber();
              formGroup.get('ins').setValue('');
              formGroup.get('ins').markAsUntouched();
              formGroup.get('ins').markAsPristine()
            "
            *ngIf="searchINS.value"
            #searchINSClearBtn
          >
            <mat-icon>close</mat-icon>
          </button>
          <button
            mat-icon-button
            style="margin: 3px"
            type="button"
            (click)="searchViaIdentifierNumber(searchINS.value)"
            [disabled]="!formGroup.get('ins').valid || !formGroup.get('ins').value"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>

        <mat-form-field>
          <input matInput [placeholder]="'itemFilter.searchPlaceHolder' | translate" #searchInput />
          <button
            type="button"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchInput.value = ''"
            [style.visibility]="searchInput.value ? 'visible' : 'hidden'"
            #searchClearBtn
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field *ngIf="('groups' | isAuthorized : 'GET' | async) === true">
          <mat-label>{{ "group.group" | translate }}</mat-label>
          <mat-select formControlName="groupFilter" [compareWith]="compareGroupId">
            <mat-option [value]="undefined"
              ><i>{{ "group.noGroupFilter" | translate }}</i></mat-option
            >
            <mat-option [value]="noGroup"
              ><i>{{ "group.withoutGroup" | translate }}</i></mat-option
            >
            <mat-option *ngFor="let item of groups" [value]="item">
              {{ item.groupName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button *ngIf="(responsiveService.isHandset$ | async) === false" type="button" mat-icon-button (click)="openPatientsHelp()">
          <mat-icon style="font-size: 30px">help_outline</mat-icon>
        </button>
      </form>
    </div>
    <div class="spacer"></div>

    <ng-container *ngIf="(responsiveService.isHandset$ | async) === false">
      <p *ngIf="isSelection">
        {{ paginator.length }}{{ "page.patientlist.selectedP1" | translate }} {{ dataSource.data.length
        }}{{ "page.patientlist.selectedP2" | translate }}.
      </p>
      <button
        mat-stroked-button
        (click)="activateCheckBox()"
        *ngIf="('getPatientUsers' | isAuthorized : 'POST' | async) === true"
        matTooltip="{{ 'page.patientlist.multiSelect' | translate }}"
      >
        <mat-icon>check_box</mat-icon>
      </button>
      <button mat-flat-button color="warn" *ngIf="isSelection" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
        <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
      </button>
      <button mat-stroked-button *ngIf="!isSelection" (click)="clearFilter()" matTooltip="{{ 'itemFilter.clearAll' | translate }}">
        <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
      </button>
      <div class="right">
        <app-item-selector
          [items]="availableColumnItems"
          *ngIf="isSlimTable === false"
          [translate]="true"
          (changed)="availableColumnChanged($event)"
          matTooltip="{{ 'page.patientlist.columnsSelect' | translate }}"
        >
        </app-item-selector>
      </div>
    </ng-container>
  </div>

  <div class="toolbar mat-elevation-z8" *ngIf="actionOnMultiple">
    <div class="column">
      <span class="pt-1 center">
        {{ "itemSelection.selection" | translate : { length: selection.selected.length } }}
      </span>
      <button mat-button (click)="selectAllPatients()" *ngIf="!forceSelected">
        {{ "page.patientlist.selectAll" | translate }}
      </button>
      <button mat-button (click)="this.selection.clear(); forceSelected = false" *ngIf="forceSelected">
        {{ "page.patientlist.unselectAll" | translate }}
      </button>
    </div>

    <div class="actions">
      <button
        mat-stroked-button
        (click)="disableSelected()"
        *ngIf="('deactivatePatient' | isAuthorized : 'PUT' | async) === true"
        [disabled]="selection.selected.length <= 0"
      >
        {{ "page.patientlist.disablePatient" | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="activateSelected()"
        *ngIf="('activatePatient' | isAuthorized : 'PUT' | async) === true"
        [disabled]="selection.selected.length <= 0"
      >
        {{ "page.patientlist.activatePatient" | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="resetPasswordSelected()"
        *ngIf="('passwordreset' | isAuthorized | async) === true"
        [disabled]="selection.selected.length <= 0"
      >
        {{ "page.patientlist.resetPassword" | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="addToGroup()"
        *ngIf="
          ('groups' | isAuthorized | async) === true &&
          ('group' | isAuthorized : 'PUT' | async) === true &&
          ('group' | isAuthorized : 'POST' | async) === true
        "
        [disabled]="isAllServices || selection.selected.length <= 0"
      >
        <span matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices">
          {{ "page.patientlist.addToGroup" | translate }}
        </span>
      </button>
      <button
        mat-stroked-button
        (click)="removeFromGroup()"
        *ngIf="('groups' | isAuthorized | async) === true && ('group' | isAuthorized : 'PUT' | async) === true"
        [disabled]="isAllServices || selection.selected.length <= 0"
      >
        <span matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices">
          {{ "page.patientlist.removeFromGroup" | translate }}
        </span>
      </button>
      <button
        mat-stroked-button
        (click)="sendCommunication()"
        *ngIf="('dashboard/communication' | isAuthorized : 'POST' | async) === true"
        [disabled]="isAllServices || selection.selected.length <= 0"
      >
        <span matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices">
          {{ "communication.sendNew" | translate }}
        </span>
      </button>
    </div>
  </div>
  <app-filters-display
    *ngIf="(responsiveService.isHandset$ | async) === false"
    [filters]="filters"
    [parent]="'patientList'"
    (removeFilter)="applyFilter($event)"
  >
  </app-filters-display>

  <mat-card class="m-0">
    <!-- Smartphone (and < 600px) list -->
    <mat-nav-list
      *ngIf="responsiveService.isHandset$ | async"
      class="p-0"
      [ngStyle]="(dataSource.loading$ | async) ? { display: 'none' } : {}"
    >
      <mat-list-item *ngFor="let patient of dataSource.rawData$ | async" class="patient-item" (click)="patientRowClicked(patient)">
        <div class="patient-details">
          <h3 matLine>{{ patient.name }}, {{ patient.firstname }}</h3>
          <p matLine>
            {{
              patient.gender === "male" || patient.gender === "female"
                ? ("choiceLabel.gender." + patient.gender | translate)
                : ("table.nc" | translate)
            }}
            - {{ patient.birthDate | date : "dd/MM/yyyy" }}
          </p>
          <p matLine>{{ patient.phone }}</p>
        </div>
        <mat-icon>chevron_right</mat-icon>
      </mat-list-item>
    </mat-nav-list>

    <!-- Table on desktop (and > 600px) -->
    <!-- We can't use *ngIf="(responsiveService.isHandset$ | async) === false" because that would call the onDestroy method and delete the dataSource (we need it for the smartphone display above) -->
    <mat-table
      [style.display]="this.displayedColumns.length > 0 && (responsiveService.isHandset$ | async) === false ? 'block' : 'none'"
      [dataSource]="dataSource"
      matSort
      matSortDirection="asc"
      matSortActive="name"
      aria-label="Elements"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="columnDropped($event)"
      class="full-width-table"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            class="ml-15"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() || forceSelected"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            class="ml-15"
            (click)="$event.stopPropagation()"
            [disabled]="forceSelected"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row) || forceSelected"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "model.patient.status" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.status' | translate }}"
            propertyName="status"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            translatePrefix="choiceLabel.statut"
            [translateFieldKey]="'model.patient.status'"
            [defaultValue]="getFilter('status')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="fas fa-ban fail" matTooltip="{{ 'choiceLabel.statut.' + row.status | translate }}" *ngIf="row.status === 0"></span>
          <span
            class="fas fa-check success"
            matTooltip="{{ 'choiceLabel.statut.' + row.status | translate }}"
            *ngIf="row.status === 1"
          ></span>
          <span
            class="fas fa-clock warning"
            matTooltip="{{ 'choiceLabel.statut.' + row.status | translate }}"
            *ngIf="row.status === 2"
          ></span>
          <span
            class="fas fa-clock success"
            matTooltip="{{ 'choiceLabel.statut.' + row.status | translate }}"
            *ngIf="row.status === 3"
          ></span>
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ country === FRANCE ? ("forms.FrName" | translate) : ("model.patient.name" | translate) }}
          <app-item-filter
            fieldName="{{ country === FRANCE ? ('forms.FrName' | translate) : ('model.patient.name' | translate) }}"
            propertyName="name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('name')"
            [translateFieldKey]="country === FRANCE ? ('forms.FrName' | translate) : ('model.patient.name' | translate)"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.name">{{ row.name }}</span>
        </mat-cell>
      </ng-container>

      <!-- useName Column -->
      <ng-container matColumnDef="useName">
        <mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "forms.useName" | translate }}
        </mat-header-cell>
        <mat-cell class="column" *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.useName">{{ row.useName }}</span>
        </mat-cell>
      </ng-container>

      <!-- Firstname Column -->
      <ng-container matColumnDef="firstname">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ country === FRANCE ? ("forms.FrFirstname" | translate) : ("model.patient.firstname" | translate) }}
          <app-item-filter
            fieldName="{{ country === FRANCE ? ('forms.FrFirstname' | translate) : ('model.patient.firstname' | translate) }}"
            propertyName="firstname"
            [dataType]="dataTypeText"
            [translateFieldKey]="country === FRANCE ? ('forms.FrFirstname' | translate) : ('model.patient.firstname' | translate)"
            [defaultValue]="getFilter('firstname')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.firstname">{{ row.firstname }}</span>
        </mat-cell>
      </ng-container>

      <!-- useFirstname Column -->
      <ng-container matColumnDef="useFirstname">
        <mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "forms.useFirstname" | translate }}
        </mat-header-cell>
        <mat-cell class="column" *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.useFirstname">{{ row.useFirstname }}</span>
        </mat-cell>
      </ng-container>

      <!-- Birthday Column -->
      <ng-container matColumnDef="birthday">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="birthDate">
          {{ "model.patient.birthdate" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.birthdate' | translate }}"
            propertyName="birthDate"
            [dataType]="dataTypeDate"
            [translateFieldKey]="'model.patient.birthdate'"
            [defaultValue]="getFilter('birthDate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>

        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.birthDate | date : 'dd/MM/yyyy'">
            {{ row.birthDate | date : "dd/MM/yyyy" }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.phone" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.phone' | translate }}"
            propertyName="phone"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('phone')"
            [translateFieldKey]="'model.patient.phone'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.phone">{{ row.phone }}</span>
        </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.email" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.email' | translate }}"
            propertyName="email"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('email')"
            [translateFieldKey]="'model.patient.email'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.email">{{ row.email }}</span>
        </mat-cell>
      </ng-container>

      <!-- Pseudoname Column -->
      <ng-container matColumnDef="pseudoname">
        <mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "model.patient.pseudoname" | translate }}
        </mat-header-cell>
        <mat-cell class="column" *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.pseudoname">{{ row.pseudoname }}</span>
        </mat-cell>
      </ng-container>

      <!-- Care provider Column -->
      <ng-container matColumnDef="careProvider">
        <mat-header-cell cdkDrag *matHeaderCellDef>{{ "model.patient.careProvider" | translate }} </mat-header-cell>
        <mat-cell class="column" *matCellDef="let row">
          <span class="truncable" *ngFor="let careProvider of getCareProvidersDisplay(row)" [matTooltip]="careProvider">{{
            careProvider
          }}</span>
        </mat-cell>
      </ng-container>

      <!-- Healthcare service Column -->
      <ng-container matColumnDef="healthcare">
        <mat-header-cell cdkDrag *matHeaderCellDef>{{ "model.patient.healthcare" | translate }} </mat-header-cell>
        <mat-cell class="column" *matCellDef="let row">
          <span class="truncable" *ngFor="let healthcare of row.healthcareservice" [matTooltip]="healthcare.display">{{
            healthcare.display
          }}</span>
        </mat-cell>
      </ng-container>

      <!-- Careplan Column -->
      <ng-container matColumnDef="careplan">
        <mat-header-cell cdkDrag *matHeaderCellDef>{{ "model.patient.careplan" | translate }} </mat-header-cell>
        <mat-cell class="column" *matCellDef="let row">
          <span class="truncable" *ngFor="let careplan of row.careplanDescriptions" [matTooltip]="careplan">{{ careplan }}</span>
        </mat-cell>
      </ng-container>

      <!-- Managing organization Column -->
      <ng-container matColumnDef="managingOrganization">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.organization" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.organization' | translate }}"
            propertyName="managingOrganization"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('managingOrganization')"
            [translateFieldKey]="'model.patient.organization'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.managingOrganization?.display">
            {{ row.managingOrganization?.display }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Insurance Column -->
      <ng-container matColumnDef="insurance">
        <mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "model.patient.insurance" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.insurance' | translate }}"
            propertyName="insurance"
            [dataType]="dataTypeText"
            [translateFieldKey]="'model.patient.insurance'"
            [defaultValue]="getFilter('insurance')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.insurance?.display">{{ row.insurance?.display }}</span>
        </mat-cell>
      </ng-container>

      <!-- lastActivity Column -->
      <ng-container matColumnDef="lastActivity">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.lastActivity" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.lastActivity | date : 'dd/MM/yyyy'">
            {{ row.lastActivity | date : "dd/MM/yyyy" }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Creation Column -->
      <ng-container matColumnDef="creation">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.creation" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.creation' | translate }}"
            propertyName="registrationDate"
            [dataType]="dataTypeDate"
            [translateFieldKey]="'model.patient.creation'"
            [defaultValue]="getFilter('registrationDate')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="truncable" [matTooltip]="row.registrationDate | date : 'dd/MM/yyyy'">
            {{ row.registrationDate | date : "dd/MM/yyyy" }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- National number Column commented waiting CMATE-1313 resolution
              <ng-container matColumnDef="nationalNumber">
                  <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
                      {{ 'model.patient.nationalNumber' | translate }}
                      <app-item-filter
                          fieldName="{{ 'model.patient.nationalNumber' | translate }}"
                          propertyName="user.nationalnumber"
                          [dataType]="dataTypeText"
                          [translateFieldKey]="'model.patient.nationalNumber'"
                          [defaultValue]="getFilter('user.nationalnumber')"
                          (applyFilter)="applyFilter($event)">
                      </app-item-filter>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row.nationalnumber }}</mat-cell>
              </ng-container>
              -->
      <!-- Gender Column -->
      <ng-container matColumnDef="gender">
        <mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>
          {{ "model.patient.gender" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.gender' | translate }}"
            propertyName="gender"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [translateFieldKey]="'model.patient.gender'"
            translatePrefix="choiceLabel.gender"
            [defaultValue]="getFilter('gender')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row.gender === 'male' || row.gender === 'female'"> {{ "choiceLabel.gender." + row.gender | translate }} </span>
          <span *ngIf="row.gender !== 'male' && row.gender !== 'female'"> {{ "table.nc" | translate }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="patientRowClicked(row)"
        [ngStyle]="(dataSource.loading$ | async) ? { display: 'none' } : {}"
      ></mat-row>
    </mat-table>

    <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <mat-paginator
      #paginator
      [pageIndex]="currentPage"
      [length]="patientsCount"
      [pageSize]="currentPageSize"
      [pageSizeOptions]="[25, 50, 100, 250]"
      [hidePageSize]="responsiveService.isHandset$ | async"
    >
    </mat-paginator>
  </mat-card>

  <!-- Message to show when no column is selected -->
  <app-no-column-info
    *ngIf="this.displayedColumns.length === 0 && this.availableColumnItems?.length"
    style="position: relative; top: 100px"
  ></app-no-column-info>
</div>
