/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ChartOptions } from "chart.js";
import * as moment from "moment";
import { BaseChartDirective } from "ng2-charts";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ObservationHelper } from "src/app/helpers/observationHelper";
import { FHIRHelper } from "../../../app/helpers/FHIRhelper";
import { Activity } from "../../../app/models/careplans.interface";
import {
  COLORS,
  ChartSettings,
  CustomPoint,
  HEIGHT_RATIO,
  IChartColor,
  IChartYAxis,
  IMinmax,
  MAX_CHART_LAST_ITEMS,
  WIDTH_RATIO,
} from "../../../app/models/chart.interface";
import {
  ComponentDisplay,
  IObservation,
  IObservationDefinition,
  ObservationDefinitionComponent,
} from "../../../app/models/observations.interface";
import { Observation } from "../../../app/models/observations.model";
import { ITranslation } from "../../../app/models/translation.interface";
import { ObservationsService } from "../../../app/providers/observations.service";
import { SessionService } from "../../../app/providers/session.service";
import { Tools } from "../../helpers/tools";
import "./chartjs-chart-financial.js";

/**
 * Container for observation data in Chart
 */
export class DataChart {
  public data: any;
  public label: string;
  public type: string;
  public pointRadius = 4;
  public pointHoverRadius = 6;
  public yAxisID: string;
  // colors
  public fill: boolean;

  [key: string]: any; // allow any properties

  constructor() {
    this.label = "";
    this.data = new Array<number>();
    this.fill = false;
    this.yAxisID = "";
  }
}

/**
 * Observation Component
 */
@Component({
  selector: "app-care-observation-chart",
  templateUrl: "./observation-chart.component.html",
  styleUrls: ["./observation-chart.component.scss"],
})
export class ObservationChartComponent implements OnInit, OnDestroy {
  private chart: BaseChartDirective;
  @ViewChild("baseChart", { static: false }) set chartCompt(c: BaseChartDirective) {
    if (c) {
      this.chart = c;
      this.reloadChart();
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Activity>;
  @ViewChild(MatSort) sort: MatSort;
  public superComponents: IObservationDefinition[];
  @Input() set superComponentsData(def: IObservationDefinition[]) {
    this.superComponents = def;
    if (this.superComponents && this.observations && this.chartCustomOpt) {
      this.computeChartData();
    }
  }
  public observations: Observation[];
  @Input() set observationsData(o: Observation[]) {
    this.observations = o;
    if (this.superComponents && this.observations && this.chartCustomOpt) {
      this.computeChartData();
    }
  }
  public chartCustomOpt: ChartSettings;
  @Input() set chartCustomOptData(c: ChartSettings) {
    this.chartCustomOpt = c;
    if (this.superComponents && this.observations && this.chartCustomOpt) {
      this.computeChartData();
    }
  }
  @Output() addObservation = new EventEmitter<IObservationDefinition>();
  @Output() editObservation = new EventEmitter<Observation>();
  private onDestroy$ = new Subject<void>();

  public colors: string[] = [];
  /**
   * Stores chart colors by LOINC code and associated color configuration.
   */
  public chartColorsByLoinc: { loinc: string; color: IChartColor }[] = [];
  /**
   * Stores previous chart colors, used to avoid regenerating colors when updating the chart.
   */
  public previousChartColors: { loinc: string; color: IChartColor }[] = [];
  public chartLabels: string[] = [];
  public chartData: DataChart[] = [];
  // observations charts parameters
  public chartOptions: ChartOptions = {
    animation: {
      duration: 0, // general animation time
    },
    elements: {
      line: { tension: 0 }, // curve of the line
    },
    hover: {
      animationDuration: 0, // duration of animations when hovering an item
      mode: "nearest",
      intersect: true,
      axis: "xy",
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    responsive: true,
    scales: {
      yAxes: [],
      xAxes: [
        {
          offset: true,
          type: "time",
          distribution: "linear",
          time: {
            minUnit: "day",
          },
          gridLines: {
            offsetGridLines: true,
            display: false,
            drawBorder: true,
          },
        },
      ],
    },
    tooltips: {
      intersect: false,
      mode: "nearest",
      axis: "xy",
      callbacks: {
        title(tooltipItem, _data) {
          if (tooltipItem) {
            const date = tooltipItem[0].label;
            return moment(date).format("LLLL");
          }
          return null;
        },
        label(tooltipItem, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          // Since we use the custom field "timingName" for the line chart,
          // we need to use y and x key while it's not necessary with other types of charts
          // more info : https://stackoverflow.com/questions/37134326/chart-js-passing-objects-instead-of-int-values-as-data

          const point = Tools.isDefined(dataset?.data[tooltipItem.index]?.y)
            ? dataset?.data[tooltipItem.index].y
            : (dataset?.data[tooltipItem.index] as CustomPoint);
          const name = dataset?.label;
          const timing = dataset?.data[tooltipItem.index]?.timingName;
          if (Tools.isDefined(point)) {
            const o = point.o;
            const h = point.h;
            const l = point.l;
            const c = point.c;

            // eslint-disable-next-line max-len
            //  Bugfix [Object, Object] on "Tension Arterielle" values based on observations taken the same day but at different time.

            // eslint-disable-next-line max-len
            //  Before push into datachart.data, We check ALL properties are truphy on observation object (t,o,h,l,c)
            if (!Tools.isValidObject(point)) {
              // We return no datas (the data is hidden)
              return;
            }

            //  Because getValue() function (on observation.model.ts - line 44) return NULL or TRUPHY value (as number)
            //  We could find objects of this type that we do not want in the data graph but still valid because of the date.
            //  Example that we don't want : { date: "2021-07-14T16:19:00+02:00" o: null, h: null, l: null, c: null }.

            if (Tools.isDefined(o) && Tools.isDefined(h) && Tools.isDefined(l) && Tools.isDefined(c)) {
              return name + " Sys :" + o + " Dia :" + c;
            } else {
              return name + " " + point + (timing ? ` (${timing})` : "");
            }
          }
        },
      },
    },
    legend: {
      display: true,
    },
  };
  public chartH: number;
  public chartW: number;

  constructor(private sessionService: SessionService, public observationsService: ObservationsService, private trans: TranslateService) {
    this.chartW = window.innerWidth * WIDTH_RATIO;
    this.chartH = window.innerHeight * HEIGHT_RATIO;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.chartW = (event.target as Window).innerWidth * 0.8;
  }

  ngOnInit(): void {
    this.trans.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe((_e: LangChangeEvent) => {
      // update charts for the right lang
      if (this.observations && this.superComponents && this.chartCustomOpt) {
        this.computeChartData();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Generate random colors
   */
  public getRandomColor(): string {
    const letters = "0123456789ABCDEF".split("");
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /**
   * Build Chart Data and layout
   */
  private computeChartData() {
    if (this.chartColorsByLoinc.length) {
      this.previousChartColors = Tools.clone(this.chartColorsByLoinc);
    }
    this.chartData = [];
    this.chartLabels = [];
    this.chartColorsByLoinc = [];
    this.chartOptions.scales.yAxes = [];

    // first filter on component loinc
    const uniqueArray = this.superComponents.filter((thing, index) => {
      return (
        index ===
        this.superComponents.findIndex((c) => ObservationHelper.ignoreSuffix(c?.loinc) === ObservationHelper.ignoreSuffix(thing.loinc))
      );
    });
    uniqueArray.forEach((superComponent) => {
      if (
        superComponent.loinc === FHIRHelper.CODE_BPCOQ ||
        superComponent.loinc === FHIRHelper.CODE_DY_COVID ||
        superComponent.loinc === FHIRHelper.CODE_BT_MM ||
        superComponent.loinc === FHIRHelper.CODE_PAIN_MM ||
        superComponent.loinc === FHIRHelper.CODE_GONFLEMENT_MM
      ) {
        superComponent.components.forEach((c, index) => {
          if (!c.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY)) {
            this.chartColorsByLoinc.push({ loinc: c.loinc, color: this.getPreviousOrNewColor(c.loinc, c.graphColor) });
            const charData = this.computeChartBarQ(superComponent.loinc, c);
            if (charData?.data?.length) {
              this.chartData.push(charData);
              this.chartOptions.scales.yAxes.push(this.computeLabelsBarQ(superComponent.loinc, c, index));
            }
          }
        });
      } else {
        // temporary hotfix https://comunicare.atlassian.net/browse/CMATE-6670
        if (superComponent.loinc.startsWith("55284-4")) {
          superComponent.chartType = "candlestick";
        }
        switch (superComponent.chartType) {
          case "line": {
            superComponent.components.forEach((c) => {
              if (!c.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY))
                this.chartColorsByLoinc.push({ loinc: c.loinc, color: this.getPreviousOrNewColor(c.loinc, c.graphColor) });
            });
            this.chartData.push(...this.computeChartLines(superComponent));
            this.chartOptions.scales.yAxes.push(...this.computeLabelsLines(superComponent));
            break;
          }
          case "range":
          case "bar": {
            const componentToDisplay = this.tryToFindCorrectComponentForBars(superComponent);
            if (!componentToDisplay) break;
            this.chartColorsByLoinc.push({
              loinc: componentToDisplay.loinc,
              color: this.getPreviousOrNewColor(componentToDisplay.loinc, componentToDisplay.graphColor),
            });
            const charData = this.computeChartBar(superComponent, componentToDisplay);
            if (charData?.data?.length) {
              this.chartData.push(charData);
              this.chartOptions.scales.yAxes.push(this.computeLabelsBar(superComponent, componentToDisplay));
            }
            break;
          }
          case "candlestick":
            this.chartColorsByLoinc.push({
              loinc: ObservationHelper.ignoreSuffix(superComponent.loinc),
              color: this.getPreviousOrNewColor(ObservationHelper.ignoreSuffix(superComponent.loinc)),
            });
            this.chartData.push(this.computeChartCandlestick(superComponent));
            this.chartOptions.scales.yAxes.push(this.computeLabelsCandlestick(superComponent));
            break;
          default:
            throw new Error("this component use an chartType we are not handle");
        }
      }
    });
    this.chartLabels = this.computeDateLabels(this.observations);
    this.reloadChart();
  }

  private computeChartLines(superComponent: IObservationDefinition): DataChart[] {
    const superCompName = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);
    const dataCharts: DataChart[] = [];
    for (const component of superComponent.components) {
      if (component.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY)) {
        continue;
      }
      let nameTranslation = component.shortnameTranslation
        ? this.getTranslation(component.shortnameTranslation, this.sessionService.userLang, component.loinc)
        : this.getTranslation(component.nameTranslation, this.sessionService.userLang, component.loinc);
      if (!nameTranslation) {
        nameTranslation = superCompName;
      }

      dataCharts.push(this.computeChartLine(component.loinc, superComponent.loinc, nameTranslation, superComponent.lsTimingWhen));
    }
    return dataCharts;
  }

  private computeChartLine(loinc: string, superCompLoinc: string, nameTranslation: string, lsTimingWhen?: any[]): DataChart {
    const dataChart = new DataChart();
    dataChart.type = "line";
    dataChart.axisColor = this.getColorByLoinc(loinc);
    dataChart.fill = false;
    dataChart.steppedLine = false;
    dataChart.spanGaps = true;
    dataChart.label = nameTranslation;
    dataChart.yAxisID = superCompLoinc + "_" + loinc;

    for (const observ of this.observations) {
      if (ObservationHelper.ignoreSuffix(observ.code.coding[0].code) !== ObservationHelper.ignoreSuffix(superCompLoinc)) {
        continue;
      }
      let timingName = "";

      if (observ.effectiveTiming && lsTimingWhen?.length) {
        const name = lsTimingWhen.find((el) => {
          return el.when.code === observ.effectiveTiming.repeat.when.code;
        }).name;
        timingName = this.getTranslation(name, this.sessionService.userLang, "");
      }
      dataChart.data.push({
        x: observ.issued,
        y: observ.getValueWithSuffix(loinc),
        timingName,
      });

      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  private computeChartBar(superComponent: IObservationDefinition, component: ObservationDefinitionComponent): DataChart {
    const dataChart = new DataChart();
    dataChart.type = "bar";
    dataChart.axisColor = this.getColorByLoinc(superComponent.loinc);
    dataChart.fill = true;
    dataChart.borderWidth = 2;
    dataChart.barThickness = 10;
    dataChart.maxBarThickness = 12;
    dataChart.label = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);
    dataChart.yAxisID = superComponent.loinc + "_" + component.loinc;

    for (const observ of this.observations) {
      if (ObservationHelper.ignoreSuffix(observ.code.coding[0].code) !== ObservationHelper.ignoreSuffix(superComponent.loinc)) {
        continue;
      }
      dataChart.data.push({
        x: observ.issued,
        y: observ.getValueWithSuffix(component.loinc),
      });
      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  private computeChartBarQ(superCompLoinc: string, component: ObservationDefinitionComponent): DataChart {
    const dataChart = new DataChart();
    dataChart.type = "bar";
    dataChart.axisColor = this.getColorByLoinc(component.loinc);
    dataChart.fill = true;
    dataChart.borderWidth = 2;
    dataChart.barThickness = 10;
    dataChart.maxBarThickness = 12;
    dataChart.label = component.shortnameTranslation
      ? this.getTranslation(component.shortnameTranslation, this.sessionService.userLang, component.loinc)
      : this.getTranslation(component.nameTranslation, this.sessionService.userLang, component.loinc);
    dataChart.yAxisID = superCompLoinc + "_" + component.loinc;

    for (const observ of this.observations) {
      if (ObservationHelper.ignoreSuffix(observ.code.coding[0].code) !== ObservationHelper.ignoreSuffix(superCompLoinc)) {
        continue;
      }
      dataChart.data.push({
        x: observ.issued,
        y: observ.getValueWithSuffix(component.loinc),
      });
      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  private computeChartCandlestick(superComponent: IObservationDefinition): DataChart {
    const dataChart = new DataChart();
    dataChart.maxBarThickness = 1;
    dataChart.type = "candlestick";
    dataChart.axisColor = this.getColorByLoinc(ObservationHelper.ignoreSuffix(superComponent.loinc));
    dataChart.label = this.getTranslation(superComponent.nameTranslation, this.sessionService.userLang, superComponent.loinc);
    dataChart.yAxisID = superComponent.loinc + "_" + ObservationHelper.ignoreSuffix(superComponent.loinc);
    for (const observ of this.observations) {
      if (ObservationHelper.ignoreSuffix(observ.code.coding[0].code) !== ObservationHelper.ignoreSuffix(superComponent.loinc)) {
        continue;
      }
      dataChart.data.push({
        t: moment(observ.issued).format(),
        o: observ.getValueWithSuffix(superComponent.components[0].loinc),
        h: observ.getValueWithSuffix(superComponent.components[0].loinc),
        l: observ.getValueWithSuffix(superComponent.components[1].loinc),
        c: observ.getValueWithSuffix(superComponent.components[1].loinc),
      });
      // do not push to many data (max 999)
      if (dataChart.data.length >= MAX_CHART_LAST_ITEMS) {
        break;
      }
    }
    dataChart.data.reverse();
    return dataChart;
  }

  /**
   * Create an y axis for each component we want to display on the graph
   * @param superComponent
   * @returns
   */
  private computeLabelsLines(superComponent: IObservationDefinition): IChartYAxis[] {
    const yaxis: IChartYAxis[] = [];
    for (const comp of superComponent.components) {
      if (comp.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY)) {
        continue;
      }
      const minmax: IMinmax = this.computeMinMax(superComponent.loinc, superComponent.components, comp.loinc);
      yaxis.push(this.computeLabels(comp.loinc, superComponent.loinc, minmax, false, true, comp));
    }
    return yaxis;
  }

  private computeLabelsBar(superComponent: IObservationDefinition, component: ObservationDefinitionComponent): IChartYAxis {
    const minmax: IMinmax = this.computeMinMax(superComponent.loinc, superComponent.components, component.loinc);
    const displayMinMax = Tools.clone(minmax);
    minmax.max = this.needBoostMax(superComponent.loinc) ? minmax.max * 6 : minmax.max;
    return this.computeLabels(component.loinc, superComponent.loinc, minmax, true, false, component, null, displayMinMax);
  }

  private computeLabelsBarQ(superCompLoinc: string, component: ObservationDefinitionComponent, position: number): IChartYAxis {
    const minmax: IMinmax = this.computeMinMax(superCompLoinc, [component], component.loinc);
    const displayMinMax = Tools.clone(minmax);
    minmax.max = minmax.max * 10;
    return this.computeLabels(component.loinc, superCompLoinc, minmax, true, false, component, position, displayMinMax);
  }

  private computeLabelsCandlestick(superComponent: IObservationDefinition): IChartYAxis {
    const minmax: IMinmax = this.computeMinMax(superComponent.loinc, superComponent.components);
    minmax.min = minmax.min - minmax.min * 0.05;
    minmax.max = minmax.max + minmax.min * 0.05;
    return this.computeLabels(ObservationHelper.ignoreSuffix(superComponent.loinc), superComponent.loinc, minmax);
  }

  private computeLabels(
    componentLoinc: string,
    superComponentLoinc: string,
    minmax: IMinmax,
    step = false,
    defaultValue = true,
    component?: ObservationDefinitionComponent,
    position?: number,
    displayMinMax?: IMinmax
  ): IChartYAxis {
    return {
      id: superComponentLoinc + "_" + componentLoinc,
      display: !Tools.isDefined(position) || position < 1 ? true : false,
      position: Tools.isDefined(position) || this.chartCustomOpt.axeYchangePosition ? "left" : "right",
      ticks: {
        fontColor: this.getColorByLoinc(componentLoinc),
        suggestedMin: minmax.min,
        suggestedMax: minmax.max,
        min: minmax.min,
        max: minmax.max,
        displayMax: displayMinMax?.max,
        displayMin: displayMinMax?.min,
        stepSize: step ? component?.step ?? 1 : null,
        callback: (value, _index, _values) => {
          if (Tools.isDefined(displayMinMax?.max) && displayMinMax.max < value) return undefined;
          if (Tools.isDefined(displayMinMax?.min) && displayMinMax.min > value) return undefined;
          if (!component || !component.meaning?.length) {
            return value;
          }
          // replace Y Axes range value by more understandable text
          for (const meaning of component.meaning) {
            // eslint-disable-next-line max-len
            if (meaning.value === value) {
              return this.getTranslation(meaning.description, this.sessionService.userLang, component.loinc);
            }
          }
          // not found, return default value or not
          return defaultValue ? value : undefined;
        },
      },
    };
  }

  private needBoostMax(loinc: string): boolean {
    return loinc !== FHIRHelper.CODE_MOOD && loinc !== FHIRHelper.CODE_INJECTION_UNIT && loinc !== FHIRHelper.CODE_ACTIVITY_DURATION;
  }

  private tryToFindCorrectComponentForBars(superComponent: IObservationDefinition): ObservationDefinitionComponent {
    if (!superComponent.components?.length) return null;
    const mainLoinc = superComponent.loinc;
    const mainLoincNoSuffix = ObservationHelper.ignoreSuffix(superComponent.loinc);
    // if there's a component with exactly the same loinc as the main, we choose this one;
    let component = superComponent.components.find(
      (c) => c.loinc === mainLoinc && !c.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY)
    );
    if (component) return component;
    // Then check if a component is the same as the main loinc, but without suffix:
    component = superComponent.components.find(
      (c) => c.loinc === mainLoincNoSuffix && !c.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY)
    );
    if (component) return component;
    // Then check if a component without suffix is the same as the main loinc without suffix:
    component = superComponent.components.find(
      (c) =>
        ObservationHelper.ignoreSuffix(c.loinc) === mainLoincNoSuffix &&
        !c.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY)
    );
    if (component) return component;
    // If we really can't find anything, just take the first component we can display:
    component = superComponent.components.find((c) => !c.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY));
    return component;
  }

  /**
   * Retrieves a previously used color for a given LOINC or generates a new one
   * @param loinc - The LOINC code
   * @param compColor (html color code) a color set for the component in DB
   * @returns The chart color object
   */
  private getPreviousOrNewColor(loinc: string, compColor?: string): IChartColor {
    // Attempt to find an existing color configuration for the provided LOINC code
    const existingColor = this.previousChartColors?.find((item) => item.loinc === loinc);
    // If an existing color configuration is found, return it; otherwise, generate a new color
    return existingColor ? existingColor.color : this.getColor(loinc, compColor);
  }

  /**
   * Generates a color configuration for a given LOINC code.
   * @param {string} loinc - The LOINC code for which the color is to be generated.
   * @param compColor (html color code) a color set for the component in DB
   * @returns {IChartColor} - The chart color object containing various color properties.
   */
  private getColor(loinc: string, compColor?: string): IChartColor {
    const color = compColor ?? this.generateColorFromLoinc(loinc);
    return {
      backgroundColor: color + "af", // bit of transparency for bar graphs
      borderColor: color,
      pointBackgroundColor: color,
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: color,
    };
  }

  /**
   * Retrieves the background color associated with a specific LOINC code.
   * @param {string} loinc
   * @returns {string} - The background color (used as main color) associated with the provided LOINC code.
   */
  private getColorByLoinc(loinc: string): string {
    const filteredColor = this.chartColorsByLoinc.filter((item) => {
      return item.loinc === loinc;
    });

    if (filteredColor.length > 1) {
      FileLogger.warn("ObservationChartComponent", "getColorByLoinc", "Multiple colors for the same loinc detected");
    }

    return filteredColor.at(0)?.color.backgroundColor;
  }

  /**
   * Generates a color based on the provided LOINC code.
   * @param {string} loinc - The LOINC code for which the color is to be generated.
   * @returns {string} - The generated color as a hexadecimal string.
   */
  private generateColorFromLoinc(loinc: string): string {
    switch (loinc) {
      case FHIRHelper.CODE_BPCOQ_A: // "49727002"
      case FHIRHelper.CODE_BODYWEIGHT: // "3141-9"
        return COLORS[0]; // blue - 0
      case FHIRHelper.CODE_HEARTRATE: // "8867-4"
      case FHIRHelper.CODE_GLUCOSE: // "2339-0"
        return COLORS[1]; // orange - 1
      case FHIRHelper.CODE_BPCOQ_C: // "365445003"
      case FHIRHelper.CODE_DY_COVID_B: // "54564-0-covid-19b"
        return COLORS[2]; // lightgreen - 2
      case FHIRHelper.CODE_BPCOQ_D: // "267036007"
      case FHIRHelper.CODE_TEMPERATURE: // "8310-5"
      case FHIRHelper.CODE_INJECTION_UNIT: // "20448-7"
        return COLORS[3]; // purple - 3
      case FHIRHelper.CODE_BPCOQ_E: // "275723000"
      case FHIRHelper.CODE_MOOD: // "80296-7"
      case FHIRHelper.CODE_DYSPNEA: // "54564-0"
      case FHIRHelper.CODE_DY_COVID_A: // "54564-0-covid-19a" (component) graph bar
        return COLORS[4]; // lightred - 4
      case FHIRHelper.CODE_FATIGUE: // "28100-6"
      case FHIRHelper.CODE_PAIN_B: // "3044-6"
        return COLORS[5]; // pink - 5
      case FHIRHelper.CODE_PAIN: // "72514-3"
      case "87705-0": // sedentarite
        return COLORS[6]; // gray - 6
      case FHIRHelper.CODE_GONFLEMENT: // "86480-1"
      case FHIRHelper.CODE_GONFLEMENT_A: // "86480-1-A" // <= new
      case FHIRHelper.CODE_GONFLEMENT_CARDIO: // "86480-1-cardio" // <= new
      case FHIRHelper.CODE_WALKING_DISTANCE: // "41953-1"
        return COLORS[7]; // green 2 - 7
      case FHIRHelper.CODE_GONFLEMENT_B: // "86480-1-B" // <= new
        return COLORS[10]; // darkgreen 10
      case FHIRHelper.CODE_BPCOQ_B: // "248595008"
      case FHIRHelper.CODE_NEUROPATHY: // "62795-0"
      case FHIRHelper.CODE_SPO2: // "20564-1"
      case FHIRHelper.CODE_COUGH: // "64145-6-covid-19" -> "64145-6" (obs AND component) graph bar (obs code is used)
      case FHIRHelper.CODE_CHILLS: // "69710-2"
      case "61930-4": // palpitations
        return COLORS[8]; // lightBlue - 8
      case FHIRHelper.CODE_BLOODPRESURE: // "55284-4"
      case FHIRHelper.CODE_REDNESS: // "66530-7"
      case FHIRHelper.CODE_ACTIVITY_DURATION: // "55411-3"
        return COLORS[9]; // red - 9
      case "85446-3": // bien-être
      case "70960-0": // ultrafiltration (pour les français)
      case "41950-7": // nb pas
        return COLORS[12]; // darkpurple
      case "32016-8-hypo": // hypo
        return COLORS[11]; // darkblue
      case "32016-8-hyper": // hyper
      case "9092-8": // quantite de sel
        return COLORS[13]; // darkred
      default:
        return this.getRandomColor();
    }
  }

  private computeDateLabels(observations: IObservation[]): string[] {
    if (!observations) {
      return;
    }
    const arr: string[] = [];
    for (const observ of observations) {
      arr.push(observ.issued);
    }
    return arr.reverse();
  }

  /**
   * In the case of candlesticks, the components are combined on one axis, so we want the combined min and max of both components.
   * In all the other cases, we only want the min and max for one component
   * @param superCompLoinc
   * @param components list of components of the observationDefinition
   * @param componentLoinc (optional) if not set, it will compute the combined min & max of all the components
   * @returns
   */
  private computeMinMax(superCompLoinc: string, components: ObservationDefinitionComponent[], componentLoinc?: string): IMinmax {
    const minMax: IMinmax = { min: 0, max: 0 };
    const componentsLoincs = componentLoinc ? [componentLoinc] : components.map((c) => c.loinc);

    const concernedComponents = components.filter(
      (c) => componentsLoincs.includes(c.loinc) && !c.display?.includes(ComponentDisplay.NO_PRACTITIONER_GRAPH_DISPLAY)
    );
    if (!concernedComponents.length) {
      return minMax;
    }
    // Get all defined max and min of each component
    const maxes: number[] = concernedComponents.map((c) => c.maxDisplay ?? c.max ?? 0);
    const mins: number[] = concernedComponents.map((c) => c.minDisplay ?? c.min ?? 0);
    // Find THE max and THE min:
    const max: number = Math.max(...maxes);
    const min: number = Math.min(...mins);
    minMax.max = max;
    minMax.min = min;
    // Check the encoded observations to see if a patient encoded a better max or min:
    this.adjustMinAndMaxWithObservationsValues(superCompLoinc, componentsLoincs, minMax);
    minMax.min = minMax.min > 0 ? minMax.min - 1 : minMax.min; // "min-1" to be able to see minimum value (or it will be on the X axis)
    return minMax;
  }

  /**
   * Adjust the default min and max for the y axis according to the encoded observations
   * @param superCompLoinc code of the observationDefinition
   * @param componentLoincs list of component for which we want to find the min and max (in most there should only be one component)
   * @param minMax (IMinMax) the default min and max
   * @returns
   */
  private adjustMinAndMaxWithObservationsValues(superCompLoinc: string, componentLoincs: string[], minMax: IMinmax): IMinmax {
    // Check min and max value encoded in observations:
    const obs = this.observations.filter((o) => o.code.coding[0].code === superCompLoinc);
    const allMins: number[] = [];
    const allMaxes: number[] = [];
    for (const observ of obs) {
      const concernedComponents = observ.component?.filter((c) => componentLoincs.includes(c.code.coding[0].code));
      const allCompValues = concernedComponents.map((c) =>
        Tools.isDefined(c.valueQuantity?.value) ? (isNaN(c.valueQuantity.value) ? null : Number(c.valueQuantity.value)) : null
      );
      const nonNullCompValues = allCompValues.filter((v) => Tools.isDefined(v));
      const maxCompValue = Math.max(...nonNullCompValues);
      const minCompValue = Math.min(...nonNullCompValues);
      if (Tools.isDefined(minCompValue)) allMins.push(minCompValue);
      if (Tools.isDefined(maxCompValue)) allMaxes.push(maxCompValue);
    }
    const allObsMin = Math.min(...allMins);
    const allObsMax = Math.max(...allMaxes);
    if (minMax.min > allObsMin) minMax.min = allObsMin;
    if (minMax.max < allObsMax) minMax.max = allObsMax;
    return minMax;
  }

  /**
   * Reload chart: Fix for the charts not updating correctly
   */
  private reloadChart() {
    if (this.chart && this.chartData && this.chartData.length) {
      this.chart.chart?.destroy();
      this.chart.ngOnDestroy();
      if (this.chart.chart) {
        this.chart.chart = null as Chart;
      }
      this.chart.datasets = this.chartData;
      this.chart.labels = this.chartLabels;
      this.chart.options = this.chartOptions;
      this.chart.ngOnInit();
    }
  }

  public getTranslation(translation: ITranslation, lang: string, txtBackup: string): string {
    return Tools.getTranslation(translation, lang, txtBackup);
  }

  public getImage(): string {
    const canvas = document.getElementById("chart") as HTMLCanvasElement;
    return canvas.toDataURL();
  }
}
